import {React,useEffect} from 'react'
import {Helmet} from "react-helmet";
import ProgressBar from '../c_components/ProgressBar';
import Footer from '../Footer/Footer'; 
import "./about.css";
import {useSelector} from "react-redux";

function About() {
  const Admin_Info_state = useSelector((state)=>state.Admin_Data_manager);
  
  


  return (
    <>
    <Helmet>
    
    <title>About</title>
    </Helmet>

    <main className=" page_container about_page">
     <div className="header_heading">
    

     <div className="wavi_title_container">
     
       <h1>About Me</h1>
       <h1>About Me</h1>
        <div className="temp_text">okokok</div>
       </div>
     </div>

   <div className="inner_container">

      {/* profile card  */}
     <div className="about_use_Container">
      <div className="profile_container">

     <div className="img_container">
         <img src={`${Admin_Info_state.profile_pic_link.url}`} alt="profile "/>

         <div  className='profile_image_cover  animate_btn_absolute'>Hover Here</div>
     </div>
             <h1>{Admin_Info_state.first_name+" "+Admin_Info_state.last_name}</h1>
             <h3>{Admin_Info_state.profession}</h3>
      </div>
     <div className="content">
       
        <p>{Admin_Info_state.about}</p>
        
        {/* Hard-working Full-stack web developer with a flair for creating elegant solutions in the least amount of time. Excited to pursue new companies to grow my skills while facilitating the empowerment and vocalization of marginalized communities. Leverage technical, analytical, and problem-solving skills to create dynamic, high-speed websites, apps, and platforms fueling competitive advantage and revenue growth. */}
          
   

     </div>
     <div className="btn_container">
           <a target="_blank" href={Admin_Info_state.resume_link.url?Admin_Info_state.resume_link.url:"none"} className='cbtn animate_btn'>Download CV</a>
           <a target="_blank" href={Admin_Info_state.website_link?Admin_Info_state.website_link:"none"} className='cbtn animate_btn'>Hire Me</a>
       </div>
    </div>
    {/* -------------------------------- */}

    <div className="sub_heading_container ">
    

     <div className="wavi_title_container">
     
       <h1>My Skills</h1>
       <h1>My Skills</h1>
        <div className="temp_text">okokok</div>
       </div>
     </div>
<div className="skills_container">

     

     {
      Admin_Info_state.skills.map(({skill,experience},index)=><ProgressBar key={index}  skill={skill} experience={experience} />)
     }


     </div>
       {/* --------------------Testi-Monial -------------- */}
       {/* <div className="sub_heading_container ">
        <div className="wavi_title_container">
    
      <h1>Clients Reviews</h1>
      <h1>Clients Reviews</h1>
       <div className="temp_text">okokok</div>
      </div>
    </div> */}
    {/* ----------------------------------- */}
    {/* <div className="clients_reviews_container">
      
    </div> */}

   </div>
    
    <Footer/>
    </main>
    </>
  )
}

export default About