import "./home.css";
import {Helmet} from "react-helmet";
import { React,useEffect,useState} from "react";
import Typewriter from 'typewriter-effect';


import {useSelector} from "react-redux";

function Home() {  
  const Admin_Info_state = useSelector((state)=>state.Admin_Data_manager);

 
  let speed=500;
      const [words, setwords] = useState([])
      
function set_words()
{
  let words2=[];
  Admin_Info_state.skills.forEach((el,i)=>{
    // console.log(el);
    words2.push(el.skill);
   })
   setwords(words2)
  //  console.log(words2);

}
useEffect(()=>{
 set_words();
},[Admin_Info_state.skills])
useEffect(()=>{
  // console.log(words);

},[words])

let home_background;
if(Admin_Info_state.home_background.url!==false)
{
   home_background="linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("+Admin_Info_state.home_background.url+")";
}
  return (

    <>
     <Helmet>
    
    <title>Home</title>
    </Helmet>
    <main className="page_container home_page" >

      <div className="bg_imageContainer"  style={{"backgroundImage":home_background}}></div>
      
      <div className="inner_wrapper">

      <h3>Welcome</h3>
      <div className="wavi_title_container">
     
       <h1>I'm a Web Developer.|</h1>
       <h1>I'm a Web Developer.|</h1>
        <div className="temp_text">okokok</div>
       </div>
       {
        words[0]==undefined && words.length==0?
            ""
            :
            <h3 className="flex_cc home_typing_text">I'm Expert in&nbsp;

      <Typewriter
  options={{
    strings: words,
    autoStart: true,
    loop: true,
  }}
/>
      </h3>
       }
       

      <a target={"_blank"} href={Admin_Info_state.website_link?Admin_Info_state.website_link:"#"} className='animate_btn cbtn'>
        Hire Me
      </a>
      </div>
    
    </main>
    </>
  )
}

export default Home