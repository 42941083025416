import "./admin.css";
import {Helmet} from "react-helmet";
import { React,useEffect,useState,useRef} from "react";
import {useDispatch,useSelector} from "react-redux";
import { Update_admin_data,uploading_progress_bar_Action } from '../State_Manager/action/actions';
import { check_login_state, https_request } from '../common_functions/functions';
import { NavLink, useNavigate} from 'react-router-dom';
import Loading from '../c_components/Loading';
import { AiTwotoneSetting } from 'react-icons/ai';
import { GoProject } from 'react-icons/go';
import { GiSkills } from 'react-icons/gi';
import { CgClose } from 'react-icons/cg';
import { BiEdit } from 'react-icons/bi';
import dummy from "../../assets/dummy.png";
import axios from 'axios';
import { toast} from 'react-toastify';

import Admin_Profile from "../c_components/Admin_Profile/Admin_Profile";
import Footer from "../Footer/Footer";
import Admin_Projects from "../c_components/Admin_Projects/Admin_Projects";
let darktheme={
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
  progress: undefined,
  }


function Admin() {  

  const toastId = useRef(null);

  const Admin_Info_state = useSelector((state)=>state.Admin_Data_manager);
  const dispatch= useDispatch();
  const host_address=useSelector((state) => state.host_address);

  const [page_container_z_index, set_page_container_z_index] = useState(5);
  const [cond_for_setting_page, set_cond_for_setting_page] = useState(false);
  const [Disabled_cond, setDisabled_cond] = useState(true);
  const [update_condition, Setupdate_condition] = useState(false);
  const [Toggle_between_Profile_projects, Set_Toggle_between_Profile_projects] = useState("");

  

  const project_type1="web";
  const project_type2="img";
  

  const navigate = useNavigate();
  const [login_condition, set_login_condition] = useState(false);
  
// -----------------------------

// function validateFileType(){
//   var fileName = document.getElementById("fileName").value;
//   var idxDot = fileName.lastIndexOf(".") + 1;
//   var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
//   if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
//       //TO DO
//   }else{
//       alert("Only jpg/jpeg and png files are allowed!");
//   }   
// }
// -----------------------------
function manage_admin_setting_page(e=null)
{
 if(e=="profile")
 {
  Set_Toggle_between_Profile_projects("profile");
}
else if(e=="projects")
{
   Set_Toggle_between_Profile_projects("projects");

 }

  let new_cond = !cond_for_setting_page;
 
  set_cond_for_setting_page(new_cond);
  setDisabled_cond(true);
  if(new_cond==true)
  {
    
    set_page_container_z_index(25);
  }else{
    Setupdate_condition("close");
    
    set_page_container_z_index(5);
  }
}
// -----------------------------
async function Upload_File_To_Server(file)
{
  

 
  let form = new FormData();
  form.append("file",file);
  
  let res = await axios.request({
    method: "post", 
    url: host_address+"upload_file", 
    withCredentials: true,
    data: form, 
    onUploadProgress: function (p){
      const progress = p.loaded / p.total;


        if (toastId.current === null) {
          toastId.current = toast.success('Upload in Progress..',darktheme);
        } else {
          toast.update(toastId.current, { progress });
        }


     
    }

    
  })
  if(res.data.status==true)
  {
    setTimeout(() => {
      toast.success(res.data.message.msg,darktheme);
      toastId.current=null;
    }, 1000);


  }
return res.data;
  


}
// -----------------------------




// -----------------------------
useEffect(() => {

  (  async ()=>{
    let check = await check_login_state();
    if(check==true)
    {
      set_login_condition(true);
      
    }else{
      setTimeout(() => {
        navigate("/login");
        
      }, 500);
      set_login_condition(false);
    }
    return true;
  })();
  
}, [])

if(login_condition==true)
{

   


  return (

    <>
     <Helmet>
    
    <title>Portfolio-Admin</title>
    </Helmet>
    <main className="page_container admin_page"  style={{"zIndex":page_container_z_index}} >
             {/* ---------------main heading ..................  */}
    <div className="header_heading">
    

    <div className="wavi_title_container">
    
      <h1>Admin Panel</h1>
      <h1>Admin Panel</h1>
       <div className="temp_text">okokok</div>
      </div>
    </div>
    {/* ---------------=________________________+++++++++++++ */}
   <div className="admin_panel_services">
    <div onClick={()=>manage_admin_setting_page("profile")}>
      <h3>Profile <i className="fas fa-cog"></i></h3>
    </div>
    <div onClick={()=>manage_admin_setting_page("projects")}>
      <h3>Projects <i className="fas fa-project-diagram"></i></h3>
    </div>
    
   </div>
{/* ------------------------------------------ */}
   <div className={cond_for_setting_page==true?"perfect_position admin_setting_container":" admin_setting_container"}>

<span id="close_admin_setting" onClick={manage_admin_setting_page}  >
  Close <CgClose/></span>
{
Disabled_cond==true?
<span id="edit_update_button" onClick={()=>{setDisabled_cond(!Disabled_cond); }}  >Edit<BiEdit/></span>   
:
<span id="edit_update_button" onClick={()=>{
  Setupdate_condition("update");
  setDisabled_cond(!Disabled_cond);
}}  >Update<BiEdit/></span>

}





{/* --------------------------------- */}
{
  Toggle_between_Profile_projects=="profile"?
  
<Admin_Profile Disabled_cond={Disabled_cond} update_condition={update_condition} Setupdate_condition={Setupdate_condition} Upload_File_To_Server={Upload_File_To_Server} />
  :
""
}
{
  Toggle_between_Profile_projects=="projects"?
  
  <Admin_Projects Disabled_cond={Disabled_cond} update_condition={update_condition} Setupdate_condition={Setupdate_condition} Upload_File_To_Server={Upload_File_To_Server}/>
  :
  ""
}



{/* --------------------------------- */}
   </div>
   

   

<Footer/>




    </main>
    </>
  )


}else
{
  return <main className="page_container login_page">

  <Loading name={"Loading"}/>
  </main>
}
}

export default Admin