import React, { useState } from 'react'
import "./icon_picker.css";
import { font_classes, Toggle_class_name } from '../../common_functions/functions';



function Icon_picker({Set_icon}) {



  const [search, setsearch] = useState("")
  const [icon_regular, setRegular] = useState(font_classes.regular)
  const [icon_solid, setSolid] = useState(font_classes.solid)
  const [icon_brands, setBrands] = useState(font_classes.brands)

  function find_query(e)
  {
    setsearch(e.target.value);
    let new_regular= font_classes.regular.filter((e,i)=>{
      if(e.includes(search))
      {
         return e;
      }
    })
    let new_solid= font_classes.solid.filter((e,i)=>{
      if(e.includes(search))
      {
         return e;
      }
    })
    let new_brands= font_classes.brands.filter((e,i)=>{
      if(e.includes(search))
      {
         return e;
      }
    })


   setSolid(new_solid)
   setRegular(new_regular)
   setBrands(new_brands)
  //  if(search.length==0 || search.length==1)
  //  {
  //   setSolid(font_classes.regular)
  //  setRegular(font_classes.solid)
  //  setBrands(font_classes.brands)
  //  }
  }
  // -------------------------------
  const icon_click_listener=(id)=>
  {
    Set_icon(id);
   

    let ele = document.querySelector(".icon_picker_root");
    Toggle_class_name(ele,"perfect_display_icon_picker","close");
    setsearch("");
  //   setSolid(font_classes.regular)
  //  setRegular(font_classes.solid)
  //  setBrands(font_classes.brands)
  }
  // -------------------------------
  return (
    <div className="icon_picker_root" >

      {/* -------------------------Icon for close div -------------- */}
      <i id='close_icon_picker' onClick={()=>{
      
            let ele = document.querySelector(".icon_picker_root");
            Toggle_class_name(ele,"perfect_display_icon_picker","close");
    }} 
      className="fas fa-times "></i>
      {/* -------------------------close div-------------- */}

      <div className="icon_picker_inner">

        <input type="search" placeholder='Enter Icon Name' name='search' value={search} onChange={find_query} />

        <div className="icon_picker_container">
          {
           icon_regular.map((d)=><i key={Math.random()} onClick={()=>icon_click_listener(`far fa-${d}`)}  className={`far fa-2x fa-${d}`}></i>)

          }
          {
            icon_solid.map((d)=><i key={Math.random()} onClick={()=>icon_click_listener(`fas fa-${d}`)} className={`fas fa-2x fa-${d}`}></i>)

          }
          {
            icon_brands.map((d)=><i key={Math.random()} onClick={()=>icon_click_listener(`fab fa-${d}`)} className={`fab fa-2x fa-${d}`}></i>)

          }

        </div>

      </div>
         

        </div>
  )
}

export default Icon_picker