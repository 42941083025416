import "./nav.css";
import { FaBars } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';

import {NavLink,useNavigate    } from "react-router-dom";
import { React,useEffect, useState} from "react";
import {useDispatch,useSelector} from "react-redux";
import { https_request,check_login_state } from "../common_functions/functions";
import {logout,login} from "../State_Manager/action/actions";

import { toast} from 'react-toastify';
let darktheme={
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
  progress: undefined,
  }
const Nav=()=>
{
  const navigate = useNavigate();
  // ----------------------------
   const login_out_state = useSelector((state)=>state.login_out_manager);
   const Admin_Info_state = useSelector((state)=>state.Admin_Data_manager);
   const host_address=useSelector((state) => state.host_address);

   const dispatch= useDispatch();
  // ----------------------------

const [toggle_btn, settoggle_btn] = useState(false);

function toggle_nav_bar_func()
{

  settoggle_btn(!toggle_btn);
}

// .........................
async function logout_user()
{
  let res = await  https_request(host_address+"logout","POST",false);
  console.log(res);
    if(res.status==true)
    {
        dispatch(logout());
     toast.success('Logout Successfully!',darktheme);
    setTimeout(() => {
      return navigate("/")
    }, 1500);

    }
} 
// .........................



useEffect(()=>{
  
  (  async ()=>{
      let check = await check_login_state();
      if(check==true)
      {
        dispatch(login());
      }
    })();
    
},[])



    return(
    <>
    
        <nav id="main_navbar">
         
          <div className="brand_container">
            <div className="brand_img_container">
                <img src={`${Admin_Info_state.profile_pic_link.url}`} alt="Profile" />
            </div>
            <h3  >{Admin_Info_state.first_name+" "+Admin_Info_state.last_name}</h3>
          </div>

          <div className="nav_links_container" >
           <NavLink    className={({ isActive }) => isActive? "active_page": 'none'}  exact="true" to="/">
            Home
           </NavLink >
           <a target="_blank" href={Admin_Info_state.resume_link.url?(`${Admin_Info_state.resume_link.url}`):""}>
            Resume
           </a >
           <NavLink  className={({ isActive }) => isActive? "active_page": "none"} exact="true" to="/portfolio">
            Portfolio
           </NavLink >
           <NavLink  className={({ isActive }) => isActive? "active_page": "none"} exact="true" to="/Services">
           Services
           </NavLink >
           <NavLink  className={({ isActive }) => isActive? "active_page": 'none'} exact="true" to="/About">
            About
           </NavLink >
         
           <NavLink  className={({ isActive }) => isActive? "active_page": "none"} exact="true" to="/contact">
            Contact
           </NavLink >
           <a  href="/logout" onClick={(e)=>{ e.preventDefault();logout_user() }} className={login_out_state==false?"dnone":""}  >
            Logout
           </a >
        
          </div>
         <div className="nav_social_icons">

          {
            Admin_Info_state.social_links.slice(0,3).map((el,i)=><a key={el.id}target="_blank" href={el.url?el.url:"dcf"} >
            <i className={el.icon_name}></i>
            </a>)
          }
          
         

         </div>
        </nav>

                 {/* responsive nav bar  */}


        <nav id="main_navbar_rs" className={toggle_btn==true?"nav_rs":""} >
         
          <div className="brand_container">
            <div className="brand_img_container">
                <img src={`${Admin_Info_state.profile_pic_link.url}`}alt="Profile" />
            </div>
            <h3  >{Admin_Info_state.first_name+" "+Admin_Info_state.last_name}</h3>
           
          </div>

          <div className="nav_links_container" >
           <NavLink    className={({ isActive }) => isActive? "active_page": 'none'} onClick={toggle_nav_bar_func} exact="true" to="/" >
            Home
           </NavLink >
           <a target="_blank" href={Admin_Info_state.resume_link.url?(`${Admin_Info_state.resume_link.url}`):""}>
            Resume
           </a >
           <NavLink  className={({ isActive }) => isActive? "active_page": "none"} onClick={toggle_nav_bar_func} exact="true" to="/portfolio">
            Portfolio
           </NavLink >
           <NavLink  className={({ isActive }) => isActive? "active_page": "none"} onClick={toggle_nav_bar_func} exact="true" to="/Services">
           Services
           </NavLink >
           <NavLink  className={({ isActive }) => isActive? "active_page": 'none'} onClick={toggle_nav_bar_func} exact="true" to="/About">
            About
           </NavLink >
        
           <NavLink  className={({ isActive }) => isActive? "active_page": "none"} onClick={toggle_nav_bar_func} exact="true" to="/contact">
            Contact
           </NavLink >
           <a  href="/logout" onClick={(e)=>{e.preventDefault(); logout_user();}} className={login_out_state==false?"dnone":""}  >
            Logout
           </a >
          </div>
         <div className="nav_social_icons">
         {
            Admin_Info_state.social_links.slice(0,3).map((el)=><a key={el.id}target="_blank" href={el.url?el.url:"example.com"} >
            <i className={el.icon_name}></i>
            </a>)
          }

         </div>
        </nav>



        <nav id="dropdown_navbar" >
          <div className="logo_container">
           <h3>{Admin_Info_state.first_name} <span>{Admin_Info_state.last_name}</span></h3>
          
          </div>
          <span id="toggle_nav_btn" onClick={toggle_nav_bar_func}>

          {
            toggle_btn==true? <CgClose/>:<FaBars/>
          }
          </span>

        </nav>
    </>
    )
}
export default Nav;