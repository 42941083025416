import React, { useState } from 'react'
import { MdOutlineAddAPhoto } from 'react-icons/md';
import Icon_picker from "../Icon_picker/Icon_picker";
import { trim_text, generate_random, Toggle_class_name, https_request } from '../../common_functions/functions';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { Update_admin_data } from '../../State_Manager/action/actions';
import "./admin_profile.css";
import { useEffect } from 'react';
import Uploading_progress_bar from '../Uploading_progress_bar/Uploading_progress_bar';

import { Test_File} from '../../common_functions/functions';
import Loading from "../Loading";
let darktheme = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
  progress: undefined,
}






function Admin_Profile({ Setupdate_condition, Disabled_cond, update_condition, Upload_File_To_Server }) {


  const Admin_Info_state = useSelector((state) => state.Admin_Data_manager);
  const host_address=useSelector((state) => state.host_address);



  const dispatch = useDispatch();
  const [temp_Admin_Data, Set_temp_Admin_Data] = useState(Admin_Info_state);

  const initial_social_data = {
    icon_name: "", id: "", url: "", title: ""
  }

  const [icon_value, setIcon_value] = useState(initial_social_data);

  //--------------- condition base useState hooks --------------
  // const [toggle_display, set_toggle_display] = useState(false);
  const [toggle_display_social_container, settoggle_display_social_container] = useState(false);
  const [conditional_alert_checker,Set_conditional_alert_checker] = useState(null);

  const [current_Icon_name, Set_current_Icon_name] = useState("");
  const [Uploading_Bar_State, set_Uploading_Bar_State] = useState({ target: "" });

  //  ---------------------Function---------------------------

  // ---------------------------------
  function Set_icon(id) {
    setIcon_value({ ...icon_value, icon_name: id });
    Set_current_Icon_name(id);


  }
  // ---------------------------------------
  // ---------------------------------
  function Add_New_Skill() {
    let rd = generate_random(6);
    let skill_field = document.getElementById("skill_field");
    let skill_experience_field = document.getElementById("skill_experience_field");

    if (!skill_field.value) {
      return toast.error('Please Enter Skill Name!', darktheme);

    }
    else if (!skill_experience_field.value) {
      return toast.error('Please Enter Experience of your Skill!', darktheme);

    }
    else if (skill_experience_field.value < 1 || skill_experience_field.value > 100) {
      return toast.error('Please Enter Experience Value between 1-100!', darktheme);

    }
    else {

      let Updated_Skill_Data = [...temp_Admin_Data.skills, { id: rd, experience: skill_experience_field.value, skill: skill_field.value }];



      const new_admin_data = {
        ...temp_Admin_Data, skills: Updated_Skill_Data
      }
      Set_temp_Admin_Data(new_admin_data);

      skill_field.value = "";
      skill_experience_field.value = "";

      return toast.success('New Skill Added😃', darktheme);

    }
  }
  // ---------------------------------
  function Add_Social_link() {
    let rd = generate_random(6);
    let url = document.getElementById("link_url");
    let url_title = document.getElementById("title_url");

    if (!icon_value.icon_name) {
      return toast.error('Please Select Icon!', darktheme);

    }
    else if (!url.value) {
      return toast.error('Please Enter url!', darktheme);

    }
    else if (!url_title.value) {
      return toast.error('Please Enter Title!', darktheme);

    }
    else {

      let new_social_link = [...temp_Admin_Data.social_links, { ...icon_value, id: rd, url: url.value, title: url_title.value }];



      const new_admin_data = {
        ...temp_Admin_Data, social_links: new_social_link
      }
      Set_temp_Admin_Data(new_admin_data);

      url.value = "";
      url_title.value = "";
      setIcon_value(initial_social_data);
    }
  }
  // ---------------------------------
  function Remove_skills_social_links(e, cond) {

    switch (cond) {
      case "social_link":
        let Updated_Social_Data = temp_Admin_Data.social_links.filter((el) => el.id != e.target.id)
        let new_admin_data1 = {
          ...temp_Admin_Data, social_links: Updated_Social_Data
        }

        Set_temp_Admin_Data(new_admin_data1);
        break;
      case "skill":
        let Updated_Data = temp_Admin_Data.skills.filter((el) => el.id != e.target.id)
        const new_admin_data = {
          ...temp_Admin_Data, skills: Updated_Data
        }

        Set_temp_Admin_Data(new_admin_data);
        break;

      default:
        toast.error("Something Wrong!", darktheme);
        break;
    }



  }
  //-------------------------Upload_File---------------
  async function Upload_File(cond) {
    let file;
    switch (cond) {
      case "resume":

        file = document.getElementById("resume_upload").files[0];
        if (Test_File(file, "image+pdf") === true) {
          Set_temp_Admin_Data({ ...temp_Admin_Data, resume_link: { url: true } })

          set_Uploading_Bar_State({ target: "resume" });

          let res = await Upload_File_To_Server(file);
          document.getElementById("resume_upload").value = null;
          if (res.status === true) {
            Set_temp_Admin_Data({ ...temp_Admin_Data, resume_link: res.data });


            set_Uploading_Bar_State({ target: "" })



          }
          else if (res.status !== true) {
            Set_temp_Admin_Data({ ...temp_Admin_Data, resume_link: { url: false } })

            set_Uploading_Bar_State({ target: "" });

            return toast.error(res.message.msg, darktheme);

          }
        }
        else {

          Set_temp_Admin_Data({ ...temp_Admin_Data, resume_link: { url: false } })
        }

        break;
      case "profile_photo":


        file = document.getElementById("profile_image").files[0];
        document.getElementById("profile_image").value = null;
        if (Test_File(file, "image") === true) {


          let res = await Upload_File_To_Server(file);
          if (res.status === true) {

            Set_temp_Admin_Data({ ...temp_Admin_Data, profile_pic_link: res.data });


          }
          else if (res.status !== true) {

            return toast.error(res.message.msg, darktheme);

          }
        }



        break;
      case "home_page_background":


        file = document.getElementById("home_page_background").files[0];
        document.getElementById("home_page_background").value = null;
        if (Test_File(file, "image") === true) {
          Set_temp_Admin_Data({ ...temp_Admin_Data, home_background: { url: true } })

          set_Uploading_Bar_State({ target: "home_background" });

          let res = await Upload_File_To_Server(file);
          if (res.status === true) {


            set_Uploading_Bar_State({ target: "" })
            Set_temp_Admin_Data({ ...temp_Admin_Data, home_background: res.data });


          }
          else if (res.status !== true) {
            Set_temp_Admin_Data({ ...temp_Admin_Data, home_background: { url: false } })

            set_Uploading_Bar_State({ target: "" });
            return toast.error(res.message.msg, darktheme);

          }
        }



        break;
      default:
        break;
    }



  }
  //----------------------------------------


  // ---------------------- set input fields---------------------
  function set_input_fields_value() {
    let input = document.getElementsByClassName("form_input");

    [...input].forEach((el, i) => {
      el.value = Admin_Info_state[el.name];
    })
  }
  // -----------------------------update input fields-----------
  async function Update_input_fields_value() {
    let input = document.getElementsByClassName("form_input");
      
    let tempvariable = temp_Admin_Data;

    [...input].forEach((el, i) => {

      tempvariable = { ...tempvariable, [el.name]: el.value };


    })
    return tempvariable;

  }
  // ----------------------------------Update_admin_data------------------
  async function update_Admin_data() {
    
    
    let new_data = await Update_input_fields_value();
    const update_res= await https_request(host_address+"update_user_data","post",new_data,true);
    if(update_res.status==true)
    {
      Setupdate_condition(false);

      dispatch(Update_admin_data(new_data));
      set_input_fields_value();
      toast.success(update_res.message.msg,darktheme);
    }else{
      toast.error(update_res.message.msg,darktheme);
    }

  }
  //----------------------validate service manager fields----------------------------
  function validate_service_manager_fields()
  {
    let s_name= document.querySelector("#service_name_input_field");
    let service_description = document.querySelector("#service_description");
    const name_max_length=30;
    const Description_min=40;
    const Description_max=150;
    

    if (!s_name.value) {
  
       toast.error('Please, Write Service Title or Name!', darktheme);
       return false;
    }
    else if (s_name.value.length<1 ||s_name.value.length>name_max_length  ) {
      toast.error('Service Name length must be between 1-'+name_max_length+' characters! \nCurrent:'+s_name.value.length, darktheme);
      return false;


    }
    else if (!service_description.value) {
      toast.error('Please, Write Short Description !', darktheme);
      return false;

      
    }
    else if (service_description.value.length<Description_min ||service_description.value.length>Description_max  ) {
      toast.error(`Short Description length must be between ${Description_min}-${Description_max} characters! \nCurrent:`+service_description.value.length, darktheme);
      return false;


    }
    else if (!current_Icon_name) {
      toast.error('Please Select Icon!', darktheme);

      return false;

    }
    else {
      return true;
    }
  }
  //----------------------Services Manager----------------------------
 function services_manager_func(e,cond)
  {
     if(cond=="create_new")
     {
      let s_name= document.querySelector("#service_name_input_field");
      let service_description = document.querySelector("#service_description");
      
     if( validate_service_manager_fields()==true)
      {
        let new_id = generate_random(6);

        let updated_service = [...temp_Admin_Data.services, {
          
          icon:current_Icon_name,
          title:s_name.value,
          short_description:service_description.value,
          id:new_id
          
        
      }];



        const new_admin_data = {
          ...temp_Admin_Data, services: updated_service
        }
        Set_temp_Admin_Data(new_admin_data);
  
        s_name.value="";
        service_description.value="";

        Set_current_Icon_name("");


       
        return toast.success('Service Added Successfully!', darktheme);

      }
     }
     else if(cond=="delete")
     {
     
          if(window.confirm("Do you want to delete this service?")==true)
          {

            
            let id = e.target.getAttribute("target");
            let Updated_Data = temp_Admin_Data.services.filter((el) => el.id != id)
            const new_admin_data = {
              ...temp_Admin_Data, services: Updated_Data
            }
            
            Set_temp_Admin_Data(new_admin_data);
          }
     }
     else if(cond=="open_for_edit")
     {
      let updateBtn = document.querySelector("#update_service_btn");
                let addBtn = document.querySelector("#add_service_btn");
                let ele = document.getElementsByClassName("services_manager");
                Toggle_class_name(ele[0],"show_service_manager","open");

                let s_name= document.querySelector("#service_name_input_field");
                let service_description = document.querySelector("#service_description");


                
                    addBtn.classList.add("dnone");
                    updateBtn.classList.remove("dnone");
                    const id = e.target.getAttribute("target");

      let curretn_service = temp_Admin_Data.services.filter((el) => el.id == id);

      s_name.value=curretn_service[0].title;
      service_description.value=curretn_service[0].short_description;
      Set_current_Icon_name(curretn_service[0].icon);
      updateBtn.setAttribute("target",id);
                    


     }
     else if(cond=="update")
     {
      let s_name = document.querySelector("#service_name_input_field");
      let service_description = document.querySelector("#service_description");
      let ele = document.getElementsByClassName("services_manager");
      let updateBtn = document.querySelector("#update_service_btn");
      let addBtn = document.querySelector("#add_service_btn");

        
    
      if( validate_service_manager_fields()==true)
        {
        
       
      const id =  e.target.getAttribute("target");
      

      let temp = temp_Admin_Data.services;
      
        temp.filter((el,i) => {

          if(el.id == id)
          {
            temp[i].title = s_name.value;

            temp[i].short_description=service_description.value;

            temp[i].icon=current_Icon_name;

          }

        });
        addBtn.classList.remove("dnone");
        updateBtn.classList.add("dnone");
    Toggle_class_name(ele[0],"show_service_manager","close");
        
        s_name.value="";
        service_description.value="";
        Set_current_Icon_name("");

      const new_admin_data = {
        ...temp_Admin_Data, services: temp
      }

      Set_temp_Admin_Data(new_admin_data);
        return toast.success('Service Updated Successfully!', darktheme);

      }



     }
  }
  //----------------------------------------------------------
  // -----------------------Condition base Useeffect-------------------
  useEffect(() => {


    if (update_condition == "close") {
      Set_temp_Admin_Data(Admin_Info_state);
      Setupdate_condition(false);

    }
    else if (update_condition == "update") {
      update_Admin_data();

    }
  }, [update_condition])

  useEffect(() => {

    set_input_fields_value();
    
    
  }, [Admin_Info_state]);

  
  // -------------------------------Return ----------------------------
  return (
    <div className="admin_profile_container">

      {/* admin picture  */}

      <div className={Disabled_cond == true ? "admin_profile_img_container" : "admin_profile_img_container  cond_disabled"}>

        <img src={`${temp_Admin_Data.profile_pic_link.url}`} alt="temp" />
        {
          Disabled_cond == true ?
            <input type="text" className="dnone" id="profile_image" />
            :
            <input type="file" name="myImage" onChange={() => Upload_File("profile_photo")} accept="image/png, image/jpeg,image/jpg" id="profile_image" className="dnone" />
        }

        <label htmlFor='profile_image'><MdOutlineAddAPhoto />
        </label>
      </div>
      {/* ////////////////////////// */}

      <form id="profile_form" onSubmit={(e) => e.preventDefault()} >

        {/* -------------------Name ----------------------- */}
        <div className="input_field">
          <label htmlFor="first_name">First Name:</label>
          <input type="text" id="first_name" name="first_name" className='form_input' placeholder="Your First Name" disabled={Disabled_cond} />
        </div>
        <div className="input_field">
          <label htmlFor="last_name">Last Name:</label>
          <input type="text" id="last_name" name="last_name" className='form_input' placeholder="Your Last Name" disabled={Disabled_cond} />
        </div>
        {/* ---------------------------------- */}
        {/* -------------------email ----------------------- */}
        <div className="input_field">
          <label htmlFor="your_email">Email:</label>
          <input type="email" id="your_email" name="email" className='form_input' placeholder="Your Email" disabled={Disabled_cond} />
        </div>
        {/* ---------------------------------- */}
        {/* -------------------Profession ----------------------- */}
        <div className="input_field">
          <label htmlFor="Profession">Profession:</label>
          <input type="text" id="Profession" name="profession" className='form_input' placeholder="Your Profession" disabled={Disabled_cond} />
        </div>
        {/* ---------------------------------- */}
        {/* -------------------Resume Url ----------------------- */}
        <div className="input_field">
          <label htmlFor="resume_upload">Upload Resume:</label>



          {/* file type input field  */}
          {
            Disabled_cond == true ?
              <input type="text" className="dnone" id="resume_upload" />
              :
              <input type="file" name="myresume" onChange={() => Upload_File("resume")} accept=".pdf,.jpg,.png,.jpeg" id="resume_upload" className="dnone" />
          }
          {/* toggle between empty or resume file icon  */}
          {

            temp_Admin_Data.resume_link.url == false
              ?
              <h5 className='empty_heading_admin'>Empty <i className="fas fa-shopping-basket"></i></h5>
              :
              ""

          }
          {

            (temp_Admin_Data.resume_link.url != false && temp_Admin_Data.resume_link.url != true)
              ?
              <span style={{ "fontSize": "4rem", "margin": "3rem 0" }}>Resume <i className="far fa-file"></i></span>
              :
              ""

          }




          {
            Uploading_Bar_State.target == "resume" ?

              <Loading name={"Uploading"} />
              :
              ""
          }


          <label htmlFor='resume_upload' className={Disabled_cond == true ? "dnone nbtn" : "nbtn"}>
            Upload CV <i className="fas fa-cloud-upload-alt"></i>
          </label>


        </div>
        {/* -------------------Home Page Background Url----------------------- */}
        <div className="input_field">
          <label htmlFor="resume_upload">Change Home Page Background:</label>



          {/* file type input field  */}
          {
            Disabled_cond == true ?
              <input type="text" className="dnone" id="home_page_background" />
              :
              <input type="file" name="myresume" onChange={() => Upload_File("home_page_background")} accept=".jpg,.png,.jpeg" id="home_page_background" className="dnone" />
          }
          {/* toggle between empty or home page file   */}
          {

            temp_Admin_Data.home_background.url === false
              ?
              <h5 className='empty_heading_admin'>Empty <i className="fas fa-shopping-basket"></i></h5>
              :
              ""

          }
          {

            (temp_Admin_Data.home_background.url != false && temp_Admin_Data.home_background.url != true)
              ?

              <img style={{ "width": "100%", "maxWidth": "20rem", "margin": "2rem auto" }} src={temp_Admin_Data.home_background.url == false ? "" : temp_Admin_Data.home_background.url} className={temp_Admin_Data.home_background.url == false ? "dnone" : ""} alt="Home page Background" />
              :
              ""

          }




          {
            Uploading_Bar_State.target == "home_background" ?

              <Loading name={"Uploading"} />
              :
              ""
          }


          <label htmlFor='home_page_background' className={Disabled_cond == true ? "dnone nbtn" : "nbtn"}>
            Upload Photo <i className="fas fa-cloud-upload-alt"></i>
          </label>




          <label onClick={() => Set_temp_Admin_Data({ ...temp_Admin_Data, home_background: { url: false } })} className={Disabled_cond == true || temp_Admin_Data.home_background.url == false ? "dnone nbtn" : "nbtn"}>
            Reset <i className="fas fa-recycle"></i>
          </label>


        </div>
        {/* ------------Freelance Website Link---------------------- */}
        <div className="input_field">
          <label htmlFor="website_link">Website Link (URl):</label>
          <input type="text" id="website_link" name="website_link" className='form_input' placeholder="Enter Website url,where clients will hire you. For Example Fiverr Account link." disabled={Disabled_cond} />
        </div>

        {/* ---------------------------------- */}
        {/* -------------------About Me ----------------------- */}
        <div className="input_field">
          <label htmlFor="About">About Me:</label>
          <textarea name="about" className='form_input' id="About" defaultValue={temp_Admin_Data.about} disabled={Disabled_cond} placeholder="About You"></textarea>
        </div>
        {/* ---------------------------------- */}
        {/* -------------------Social Icons ----------------------- */}

        {/* social icon div start  */}
        <div className="input_field">
          <label>Social Links:</label>

          {/* links container  */}

          <div id="my_social_links" >

            {/* -----------------link */}
            {
              temp_Admin_Data.social_links.map((val, i) => <div key={i} className={Disabled_cond == true ? "link" : "link link_after"}  >
                <i className={val.icon_name}></i>
                <span>{trim_text(val.url, 15)}</span>

                <i id={val.id} onClick={(e) => Remove_skills_social_links(e, "social_link")} className={Disabled_cond == true ? "dnone remove_link fas fa-times" : "remove_link fas fa-times"} ></i>

              </div>)


            }
            {

              (temp_Admin_Data.social_links.length == 0 && temp_Admin_Data.social_links[0] == undefined)
                ?
                <h5 className='empty_heading_admin'>Empty <i className="fas fa-shopping-basket"></i></h5>
                : ""

            }

            {/* -------------------Social Icons ----------------------- */}


          </div>

          <button id="add_social_btn" style={{ "marginTop": "3rem" }} className={Disabled_cond == true ? "dnone" : ""} onClick={() => settoggle_display_social_container(true)} >Add Social Link</button>

        </div>
        {/* social icon div ends  */}
        {/* -------------------My Skills ----------------------- */}
        <div className="input_field  add_new_skill_div">
          <label>My Skills:</label>

          <div style={{ "width": "100%" }} className={Disabled_cond == true ? "dnone" : ""} id="add_new_skill_div">

            <input type="text" placeholder='Enter Skill Name' id='skill_field' />
            <input type="number" max="100" min="1" placeholder='Enter Experience Level between 1-100%.' id='skill_experience_field' />

            <button style={{ "marginTop": "3rem" }} className="nbtn" onClick={Add_New_Skill} >Add Skill</button>
          </div>

          <div className="skill_container">


            {
              temp_Admin_Data.skills.map((val, i) =>
                <span className={Disabled_cond == true ? "link" : "link link_after"} key={val.id}  >

                  {val.skill}

                  <i onClick={(e) => Remove_skills_social_links(e, "skill")} id={val.id} className={Disabled_cond == true ? "dnone remove_link fas fa-times" : "remove_link fas fa-times"} ></i>


                </span>
              )
            }

          </div>




          {/* --------------------------- */}
          {

            (temp_Admin_Data.skills.length == 0 && temp_Admin_Data.skills[0] == undefined)
              ?
              <h5 className='empty_heading_admin'>Empty <i className="fas fa-shopping-basket"></i></h5>
              : ""

          }
        </div>
        {/* ----------------------------------------------- */}
        {/* -------------------My Services ----------------------- */}
        <div className="input_field">
          <label htmlFor="admin_services_container">My Services:</label>
               {/* --------------------------------------------- */}

              <button className={Disabled_cond == true ? "dnone" : "cbtn"} onClick={()=>{
                let ele = document.getElementsByClassName("services_manager");

                Toggle_class_name(ele[0],"show_service_manager","open");
              }} >Create Service</button>
               {/* --------------------------------------------- */}

          <div id="admin_services_container">

            {
              // fetching services 
              temp_Admin_Data.services.map((val,i)=> <div key={val.id} className="admin_service">
              <i className={val.icon}></i>
              <h3>{val.title}</h3>

              <div className={Disabled_cond == true ? "dnone" : "toggle_button_container"} >

                <i className="fas fa-edit" title='edit' target={val.id} target_type="edit" onClick={(e)=>services_manager_func(e,"open_for_edit")}   ></i>

                <i className="fas fa-trash-alt" title='delete' onClick={(e)=>services_manager_func(e,"delete")}  target={val.id} target_type="del"></i>
              </div>
            </div>)

            }
            {

(temp_Admin_Data.services.length == 0 && temp_Admin_Data.services[0] == undefined)
  ?
  <h5 className='empty_heading_admin'>Empty <i className="fas fa-shopping-basket"></i></h5>
  : ""

}
          </div>


        </div>
        {/* ---------------------------------- */}
      </form>


      {/* global components for this  page  */}


      {/* ------------------------ Services Manager ........................  */}
      <div className="services_manager">
      <i id="close_services_manager" onClick={()=>{
                let ele = document.getElementsByClassName("services_manager");
                let updateBtn = document.querySelector("#update_service_btn");
                document.querySelector("#service_name_input_field").value="";
                document.querySelector("#service_description").value="";
                Set_current_Icon_name("");

                let addBtn = document.querySelector("#add_service_btn");
                    addBtn.classList.remove("dnone");
                    updateBtn.classList.add("dnone");
                Toggle_class_name(ele[0],"show_service_manager","close");
              }}  className='fas fa-times'></i>

        <div className="inner">
          <h1>Services Manager</h1>
          <input type="text" id='service_name_input_field' placeholder='Service Name OR Title' />
          <textarea name="service_description" id="service_description" placeholder='Short Description...'/>
          <div>
            <button onClick={()=>{      
      let ele = document.querySelector(".icon_picker_root");
      Toggle_class_name(ele,"perfect_display_icon_picker","open");
}} >Select Icon</button>
            <i id='service_manager_icon_preview' className={current_Icon_name}></i>

          </div>
          <button id='add_service_btn' onClick={(e)=>services_manager_func(e,"create_new")} className='cbtn'>Add Service</button>
          <button id='update_service_btn' target="" className=" cbtn dnone" onClick={(e)=>services_manager_func(e,"update")} >Update Service</button>

        </div>
      </div>
      {/* ------------------------------------------ */}


      <div className={toggle_display_social_container == true ? "add_social_links_container perfect_position_social_container" : "add_social_links_container"}>

        {/* -------------------------Icon for close div -------------- */}
        <i id='close_social_link_container' onClick={() => settoggle_display_social_container(false)}
          className="fas fa-times "></i>
        {/* -------------------------close div-------------- */}

        <div className="inner">

          <div>

            <input type="text" name='url' id='link_url' placeholder='Enter URL Here*' />
            <i className={icon_value.icon_name} ></i>
            <input type="text" name='url_title' id='title_url' placeholder='Enter Title*' />


          </div>
          <button className='nbtn' onClick={()=>{      
      let ele = document.querySelector(".icon_picker_root");

      Toggle_class_name(ele,"perfect_display_icon_picker","open");
}} >Select Icon</button>
          <button className='nbtn' onClick={Add_Social_link}>Add Social Link</button>

        </div>

      </div>

      {/* -[---------] */}

      <Icon_picker  Set_icon={Set_icon} />
       
    </div>
  )
}

export default Admin_Profile