import {React,useEffect} from 'react'
import {Helmet} from "react-helmet";
import Service from '../c_components/Service';
import Footer from '../Footer/Footer';
import "./services.css";

import { FaLaptopCode } from 'react-icons/fa';
import { FaPaintBrush } from 'react-icons/fa';
import { FaWordpress } from 'react-icons/fa';
import { SiAdobeillustrator } from 'react-icons/si';
import { BiCustomize } from 'react-icons/bi';
import { FaReact } from 'react-icons/fa';
import { FaFigma } from 'react-icons/fa';

import {useSelector} from "react-redux";

function Services() {

  const Admin_Info_state = useSelector((state)=>state.Admin_Data_manager);
  
let services_list = [
  {
    name:"Website Development",icon:<FaLaptopCode/>
  },
 
  {
    name:"Wordpress Development",icon:<FaWordpress/>
  },
  {
    name:"Mern Stack Development",icon:<FaReact/>
  }, {
    name:"UI/UX Designing",icon:<FaPaintBrush/>
  },
  {
    name:"Logo Designing",icon:<SiAdobeillustrator/>
  },
  {
    name:"Figma/XD/PSD To Html",icon:<FaFigma/>
  },
  {
    name:"Customization",icon:<BiCustomize/>
  },

]


  return (
    <>
    <Helmet>
    
    <title>Services</title>
    </Helmet>

    <main className=" page_container about_page">
     <div className="header_heading">
    

     <div className="wavi_title_container">
     
       <h1>My Services</h1>
       <h1>My Services</h1>
        <div className="temp_text">okokok</div>
       </div>
     </div>

       {/* services started  */}
       
       <div className="services_container">

       

        {
          Admin_Info_state.services.map((data,index)=> <Service data={data} key={index} />)
        }
       
       {

(Admin_Info_state.services.length == 0 && Admin_Info_state.services[0] == undefined)
  ?
  <h5 className='empty_heading'>Empty <i className="fas fa-shopping-basket"></i></h5>
  : ""

}
       </div>



       {/* ---------------------- */}
    
    <Footer/>
    </main>
    </>
  )
}

export default Services