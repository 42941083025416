import React from 'react'
import "./Service.css";
function Service({data}) {
  return (
    <div className="service">
          <div className="service_logo_container">
            <span>
            <i className={data.icon}></i>
            </span>
          </div>
          <h3>{data.title}</h3>
          <p>{data.short_description}</p>

        </div>
  )
}

export default Service