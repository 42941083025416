import {React,useEffect,useState} from 'react'

function ProgressBar({skill,experience}) {
 const [value, setvalue] = useState(0);
    useEffect(()=>{
        let page= document.querySelector(".about_page");
        page.addEventListener('scroll', function() {
          var element = document.querySelector('.progress_bar_outer');
          
              var position = element.getBoundingClientRect();
// checking whether fully visible
if(position.top >= 0 && position.bottom <= window.innerHeight) {
    setvalue(experience);
}
  // checking for partial visibility
//   if(position.top < window.innerHeight && position.bottom >= 0) {
//     setvalue(experience);
// }else{
    
//     setvalue(0);
//   }
        
        
          
        
          
        });
      
      })

  return (
    <div className="progress_bar_container">
      <h3>{skill}</h3>
      <div className="progress_bar_outer">
        <span>{experience}%</span>
        <div className="progress_bar_inner" style={{width:`${value}%`,transition:"3s"}} >

        </div>
      </div>
     </div>
  )
}

export default ProgressBar