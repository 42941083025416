import {Helmet} from "react-helmet";

import {React,useState} from 'react';
import "./error_page.css";
function Error_page() {
  const [page_container_z_index, set_page_container_z_index] = useState(5);

  return (
   <>
    <Helmet>
    
    <title>Portfolio-404</title>
    </Helmet>
    <main className="page_container admin_page"  style={{"zIndex":page_container_z_index}} >

        {/* ---------------main heading ..................  */}
        <div className="header_heading page_not_found_heading">
    

    <div className="wavi_title_container">
    
      <h1>Page Not Found</h1>
      <h1>Page Not Found</h1>
       <div className="temp_text">okokok</div>
      </div>
    </div>
    </main>
   </>
  )
}

export default Error_page