import React from 'react'
import {useDispatch,useSelector} from "react-redux";

import "./footer.css";
function Footer() {

  const Admin_Info_state = useSelector((state)=>state.Admin_Data_manager);
  const dispatch= useDispatch();

  return (
    <>

  <div className="footer">
    <div className="bubbles">
      <div className="bubble" style={{"--size":"2.6123198891585497rem","--distance":"6.983016037423044rem","--position":"101.49684217787151%","--time":"3.663751391479186s","--delay":"-3.4391497626027308s"}} />
      <div className="bubble" style={{"--size":"3.5514755538358527rem","--distance":"7.563471673892591rem","--position":"90.38221764463823%","--time":"3.804566471871151s","--delay":"-2.432501089158374s"}} />
      <div className="bubble" style={{"--size":"4.719227551770032rem","--distance":"8.58328735094415rem","--position":"45.448266773267086%","--time":"2.0506023972082965s","--delay":"-2.519412563583255s"}} />
      <div className="bubble" style={{"--size":"2.4449731567614608rem","--distance":"8.354872600528104rem","--position":"85.10012856838266%","--time":"2.145310720078075s","--delay":"-2.828289189538038s"}} />
      <div className="bubble" style={{"--size":"3.8895130165701532rem","--distance":"6.7162796244894665rem","--position":"67.26276859629604%","--time":"3.0286722242039352s","--delay":"-2.4929378224764522s"}} />
      <div className="bubble" style={{"--size":"2.641161243864455rem","--distance":"6.790868827242215rem","--position":"81.52079112310425%","--time":"3.558251675868352s","--delay":"-2.297427453323801s"}} />
      <div className="bubble" style={{"--size":"2.060317938642564rem","--distance":"9.041986419599674rem","--position":"30.054222615105452%","--time":"3.5041135137355433s","--delay":"-2.7966351405849936s"}} />
      <div className="bubble" style={{"--size":"5.840423734329172rem","--distance":"7.07545129376998rem","--position":"26.89409469509159%","--time":"2.0234134309682066s","--delay":"-3.9628168828618997s"}} />
      <div className="bubble" style={{"--size":"4.331803048019466rem","--distance":"8.769103942854574rem","--position":"39.632323879908256%","--time":"3.996187725470463s","--delay":"-3.6015172792940064s"}} />
      <div className="bubble" style={{"--size":"4.062235809160619rem","--distance":"9.266844202442439rem","--position":"42.416309843013345%","--time":"2.0889200458035915s","--delay":"-3.671386406610323s"}} />
      <div className="bubble" style={{"--size":"2.953468746589599rem","--distance":"6.721790684124963rem","--position":"44.2080619307967%","--time":"3.4153392106434075s","--delay":"-2.7807199100582753s"}} />
      <div className="bubble" style={{"--size":"4.360370281787748rem","--distance":"8.012221075992315rem","--position":"62.96310773796844%","--time":"3.862538800126249s","--delay":"-3.2545494594148647s"}} />
      <div className="bubble" style={{"--size":"5.110406655603356rem","--distance":"6.911185439363975rem","--position":"58.13676690944222%","--time":"3.012532034094225s","--delay":"-3.569295134088785s"}} />
      <div className="bubble" style={{"--size":"4.906222315007669rem","--distance":"8.929293532568906rem","--position":"77.83154648217139%","--time":"3.6207172170383126s","--delay":"-3.2832874469055797s"}} />
      <div className="bubble" style={{"--size":"2.035512643782833rem","--distance":"9.154433935687631rem","--position":"78.26999717716444%","--time":"2.607442444104299s","--delay":"-3.778794154196196s"}} />
      <div className="bubble" style={{"--size":"2.232307733395717rem","--distance":"9.551264789350093rem","--position":"26.51623854559344%","--time":"3.422712828978553s","--delay":"-2.434337803030408s"}} />
      <div className="bubble" style={{"--size":"2.409433178075753rem","--distance":"6.829428718840411rem","--position":"87.36371962443552%","--time":"2.9930380852690237s","--delay":"-2.2657615057375606s"}} />
      <div className="bubble" style={{"--size":"4.781138861331028rem","--distance":"6.249410443097364rem","--position":"31.055104996366254%","--time":"3.4744405454438154s","--delay":"-2.5313791440230884s"}} />
      <div className="bubble" style={{"--size":"5.611691533789919rem","--distance":"8.082051825489785rem","--position":"56.18062513106579%","--time":"2.1632166204738907s","--delay":"-2.3330499382251695s"}} />
      <div className="bubble" style={{"--size":"2.0240002866780182rem","--distance":"7.99296156436964rem","--position":"5.731925279091641%","--time":"2.404567320589698s","--delay":"-2.492123147286518s"}} />
      <div className="bubble" style={{"--size":"5.642802186474621rem","--distance":"8.744767890227273rem","--position":"46.39698995225104%","--time":"2.481157278299198s","--delay":"-2.370082472760521s"}} />
      <div className="bubble" style={{"--size":"4.754854505007278rem","--distance":"9.38462079977565rem","--position":"82.14162464883323%","--time":"2.3295009662319135s","--delay":"-3.5229808076187688s"}} />
      <div className="bubble" style={{"--size":"4.445550527768078rem","--distance":"7.280633001640286rem","--position":"42.09705223434058%","--time":"3.5610148866007663s","--delay":"-2.155368262638303s"}} />
      <div className="bubble" style={{"--size":"5.457097547923311rem","--distance":"7.926342349349267rem","--position":"-0.7582824758583655%","--time":"2.89484739313945s","--delay":"-3.731325543286653s"}} />
      <div className="bubble" style={{"--size":"4.8688507537397285rem","--distance":"8.651066667723791rem","--position":"42.350301519472715%","--time":"2.7762598141761425s","--delay":"-2.499613570621838s"}} />
      <div className="bubble" style={{"--size":"3.3183794185704123rem","--distance":"9.898016249602236rem","--position":"89.29983745692066%","--time":"3.3379677620748667s","--delay":"-2.980046284642296s"}} />
      <div className="bubble" style={{"--size":"4.661871665018195rem","--distance":"8.928231440997408rem","--position":"20.887336653997764%","--time":"2.394913127514205s","--delay":"-3.6306128185400985s"}} />
      <div className="bubble" style={{"--size":"4.743793632146019rem","--distance":"6.636122389898395rem","--position":"32.99579214210316%","--time":"3.32195779116172s","--delay":"-2.251014666142924s"}} />
      <div className="bubble" style={{"--size":"4.739262652150695rem","--distance":"8.705042426343603rem","--position":"78.7593975985676%","--time":"3.4646355700975224s","--delay":"-3.8796495283571892s"}} />
      <div className="bubble" style={{"--size":"4.202865494611074rem","--distance":"8.245573974830261rem","--position":"-4.4706796336029%","--time":"3.965533604012226s","--delay":"-3.193944307504598s"}} />
      <div className="bubble" style={{"--size":"5.422143325689059rem","--distance":"9.204318312497987rem","--position":"0.9736647612911842%","--time":"2.1072875981315744s","--delay":"-3.059856064580409s"}} />
      <div className="bubble" style={{"--size":"2.4507725663421684rem","--distance":"8.624289625355058rem","--position":"81.07645149570234%","--time":"2.9725074953076294s","--delay":"-2.324183849004526s"}} />
      <div className="bubble" style={{"--size":"5.198808002726441rem","--distance":"7.2648962535014245rem","--position":"64.54459982634295%","--time":"3.0499905266734864s","--delay":"-2.300455095050856s"}} />
      <div className="bubble" style={{"--size":"3.5114563715807607rem","--distance":"8.801395873574196rem","--position":"92.49092711042267%","--time":"2.0332432593285685s","--delay":"-2.141345967269195s"}} />
      <div className="bubble" style={{"--size":"4.6681502993759825rem","--distance":"9.88864195354632rem","--position":"53.75235485456089%","--time":"3.1756298114549266s","--delay":"-3.1336825812536286s"}} />
      <div className="bubble" style={{"--size":"2.7823201221617806rem","--distance":"7.916808533727094rem","--position":"42.97319254869975%","--time":"3.286679433398831s","--delay":"-2.4747338218733343s"}} />
      <div className="bubble" style={{"--size":"3.7063360014084683rem","--distance":"7.040586030869882rem","--position":"87.90501193924617%","--time":"3.045619755493939s","--delay":"-3.1198398645174823s"}} />
      <div className="bubble" style={{"--size":"5.984904746587718rem","--distance":"8.17167042686815rem","--position":"82.55229970592218%","--time":"3.7378431850199987s","--delay":"-3.6750626003915436s"}} />
      <div className="bubble" style={{"--size":"3.5565492725532497rem","--distance":"8.974779627434248rem","--position":"74.26481290115456%","--time":"2.3246651138938663s","--delay":"-3.418605838329315s"}} />
      <div className="bubble" style={{"--size":"4.817229822914225rem","--distance":"8.433225442425563rem","--position":"89.56959185527288%","--time":"3.071310712547396s","--delay":"-3.773447206440688s"}} />
      <div className="bubble" style={{"--size":"2.6853796280744104rem","--distance":"6.5704784027748335rem","--position":"20.388963516652737%","--time":"2.6429403553454294s","--delay":"-3.411098448339515s"}} />
      <div className="bubble" style={{"--size":"3.3218925936130574rem","--distance":"9.899547147720199rem","--position":"80.7798738278701%","--time":"3.289481307604077s","--delay":"-3.8418842641886433s"}} />
      <div className="bubble" style={{"--size":"4.598578409294324rem","--distance":"9.534825175472731rem","--position":"34.84795697331989%","--time":"2.2064682589114204s","--delay":"-3.407150494679171s"}} />
      <div className="bubble" style={{"--size":"2.006754722671328rem","--distance":"9.158030268607789rem","--position":"1.1167626801051522%","--time":"2.3276109525984157s","--delay":"-2.6690968149032885s"}} />
      <div className="bubble" style={{"--size":"4.440635622425838rem","--distance":"6.045938393853542rem","--position":"91.06319523680502%","--time":"3.74388575772121s","--delay":"-2.5712745876247203s"}} />
      <div className="bubble" style={{"--size":"5.770458414040017rem","--distance":"6.819416307158329rem","--position":"92.82246310488357%","--time":"2.1344291632307635s","--delay":"-2.9950224289231864s"}} />
      <div className="bubble" style={{"--size":"2.4524991262993963rem","--distance":"7.40603600876842rem","--position":"22.799055870015817%","--time":"3.1144165023412977s","--delay":"-3.214896997054078s"}} />
      <div className="bubble" style={{"--size":"5.201574254330367rem","--distance":"7.7557297483645815rem","--position":"91.9753113811674%","--time":"3.1541509153783966s","--delay":"-2.9845450036763768s"}} />
      <div className="bubble" style={{"--size":"4.984480535141582rem","--distance":"8.467296296588035rem","--position":"13.173783267686105%","--time":"2.5907051528331597s","--delay":"-3.2379798885723816s"}} />
      <div className="bubble" style={{"--size":"5.037040754604646rem","--distance":"6.824742989383797rem","--position":"75.61441696926511%","--time":"2.2528223237320697s","--delay":"-2.8226566189419455s"}} />
      <div className="bubble" style={{"--size":"2.7122232402143958rem","--distance":"6.127666139875709rem","--position":"66.17061794374854%","--time":"2.2804068461320868s","--delay":"-3.9901877576205287s"}} />
      <div className="bubble" style={{"--size":"5.230108774967998rem","--distance":"7.415534694885656rem","--position":"5.98812948258775%","--time":"2.6754787168344816s","--delay":"-2.57324672352321s"}} />
      <div className="bubble" style={{"--size":"2.625971419924719rem","--distance":"9.84211424839168rem","--position":"89.43248303628667%","--time":"3.771575164875537s","--delay":"-3.7730621955870247s"}} />
      <div className="bubble" style={{"--size":"3.9279911431185317rem","--distance":"8.559076417820616rem","--position":"81.90417981110068%","--time":"3.457270342390632s","--delay":"-2.105430243328542s"}} />
      <div className="bubble" style={{"--size":"3.5956930255553914rem","--distance":"7.516068001089447rem","--position":"65.88719492174897%","--time":"3.401012769587615s","--delay":"-2.1039707911106458s"}} />
      <div className="bubble" style={{"--size":"4.590529970489396rem","--distance":"8.121939270352248rem","--position":"76.75339187961774%","--time":"3.8800029244049212s","--delay":"-3.266487103875423s"}} />
      <div className="bubble" style={{"--size":"4.9318198731423rem","--distance":"8.904064477209822rem","--position":"-4.424272613605034%","--time":"2.814773515569195s","--delay":"-3.9210289986551463s"}} />
      <div className="bubble" style={{"--size":"5.083693670031003rem","--distance":"8.805555587993549rem","--position":"71.24491522118875%","--time":"2.2529373491385853s","--delay":"-2.191551336631567s"}} />
      <div className="bubble" style={{"--size":"2.3238516680240044rem","--distance":"9.819804654068054rem","--position":"42.99428363488374%","--time":"2.3156759028131324s","--delay":"-2.04232317459885s"}} />
      <div className="bubble" style={{"--size":"5.503953839201841rem","--distance":"9.689892785720804rem","--position":"14.295983790317823%","--time":"2.455343479784798s","--delay":"-2.2628388917227724s"}} />
      <div className="bubble" style={{"--size":"3.5902206863548995rem","--distance":"8.306846495204224rem","--position":"-3.262486967528846%","--time":"2.9264518928166696s","--delay":"-3.5000023121210444s"}} />
      <div className="bubble" style={{"--size":"4.267256333602164rem","--distance":"8.961258852154128rem","--position":"88.23875129323291%","--time":"2.6616530882076694s","--delay":"-3.8878141622385165s"}} />
      <div className="bubble" style={{"--size":"4.483253113919214rem","--distance":"6.335129965537191rem","--position":"61.9682558764806%","--time":"2.6876053425204423s","--delay":"-3.2404697808628495s"}} />
      <div className="bubble" style={{"--size":"4.844959469217164rem","--distance":"8.284524312023432rem","--position":"18.003558826356176%","--time":"3.0222020497727837s","--delay":"-2.8501693862949127s"}} />
      <div className="bubble" style={{"--size":"5.213770932836457rem","--distance":"6.006785692787395rem","--position":"6.224259466594695%","--time":"3.885013322549753s","--delay":"-2.254943799193405s"}} />
      <div className="bubble" style={{"--size":"5.601375683367076rem","--distance":"6.996109472799996rem","--position":"18.57292447774016%","--time":"2.5966439892172293s","--delay":"-3.3363933130616434s"}} />
      <div className="bubble" style={{"--size":"5.623941422897606rem","--distance":"9.178648247489743rem","--position":"73.40789277446525%","--time":"2.088752856752655s","--delay":"-2.277457623416388s"}} />
      <div className="bubble" style={{"--size":"4.437108850386517rem","--distance":"8.577770286241517rem","--position":"93.92167131060232%","--time":"3.093366095093206s","--delay":"-3.423292364847261s"}} />
      <div className="bubble" style={{"--size":"4.146111824585248rem","--distance":"7.247410030352388rem","--position":"-2.8789849845412063%","--time":"3.8030151556711136s","--delay":"-3.845066083645348s"}} />
      <div className="bubble" style={{"--size":"4.36789446587145rem","--distance":"7.047853098604225rem","--position":"10.05701379497869%","--time":"3.8010236086464397s","--delay":"-2.5738264443281054s"}} />
      <div className="bubble" style={{"--size":"3.2877449945002013rem","--distance":"9.612411491871129rem","--position":"70.2121640811817%","--time":"2.700548903609272s","--delay":"-3.2683640317706018s"}} />
      <div className="bubble" style={{"--size":"2.596566613502059rem","--distance":"9.028168331323446rem","--position":"19.304538490158627%","--time":"3.627690830289364s","--delay":"-2.3593759003867403s"}} />
      <div className="bubble" style={{"--size":"2.1205997354096278rem","--distance":"6.296645751774429rem","--position":"1.6343773927313299%","--time":"2.146541132872821s","--delay":"-3.4924182023059087s"}} />
      <div className="bubble" style={{"--size":"2.4793261606065364rem","--distance":"6.749035556181546rem","--position":"33.47835839268235%","--time":"3.9102269438987514s","--delay":"-2.70370768673326s"}} />
      <div className="bubble" style={{"--size":"5.622258799631002rem","--distance":"7.898038753724227rem","--position":"34.60465354643815%","--time":"2.955206959591664s","--delay":"-2.0510292359674667s"}} />
      <div className="bubble" style={{"--size":"2.269825662551405rem","--distance":"8.251539483359023rem","--position":"40.412071954455214%","--time":"3.2287326799195s","--delay":"-3.680688176100418s"}} />
      <div className="bubble" style={{"--size":"5.241484889902907rem","--distance":"6.5543929248785595rem","--position":"10.627414712251495%","--time":"2.0600910192178s","--delay":"-3.338108666854672s"}} />
      <div className="bubble" style={{"--size":"5.677111759110169rem","--distance":"8.85689352224012rem","--position":"94.41273931218707%","--time":"2.798096231778658s","--delay":"-2.607510757366528s"}} />
      <div className="bubble" style={{"--size":"4.673795463441339rem","--distance":"7.852499753838599rem","--position":"47.65170238009069%","--time":"2.4971361641277108s","--delay":"-3.3878548038769387s"}} />
      <div className="bubble" style={{"--size":"3.338035515779014rem","--distance":"8.185217588889332rem","--position":"23.38688101853493%","--time":"3.592401744889899s","--delay":"-3.1240065154726135s"}} />
      <div className="bubble" style={{"--size":"4.985942831417044rem","--distance":"9.470869032465611rem","--position":"75.63145803653278%","--time":"2.1566895111832647s","--delay":"-3.5102108557708553s"}} />
      <div className="bubble" style={{"--size":"2.654916757097377rem","--distance":"6.9963697630464825rem","--position":"84.26101964575001%","--time":"3.708048145147476s","--delay":"-2.1394257589926626s"}} />
      <div className="bubble" style={{"--size":"3.502132258111848rem","--distance":"9.680650521925728rem","--position":"91.50553894830557%","--time":"2.114321713997531s","--delay":"-2.9864908610434906s"}} />
      <div className="bubble" style={{"--size":"2.66161697113864rem","--distance":"6.827413262615872rem","--position":"76.40760188094137%","--time":"2.259463944207315s","--delay":"-3.2111144133621705s"}} />
      <div className="bubble" style={{"--size":"3.290862516841619rem","--distance":"8.822906250502797rem","--position":"58.75553120738578%","--time":"2.949125135051459s","--delay":"-3.1539200532110416s"}} />
      <div className="bubble" style={{"--size":"5.001706203131373rem","--distance":"8.37799157872703rem","--position":"39.45413079600178%","--time":"2.5736948756216296s","--delay":"-2.20592853355143s"}} />
      <div className="bubble" style={{"--size":"4.340779051539735rem","--distance":"7.974376175151669rem","--position":"96.62795222361159%","--time":"2.4937562178059505s","--delay":"-2.53593792690125s"}} />
      <div className="bubble" style={{"--size":"3.7373981859659464rem","--distance":"9.032473045838092rem","--position":"88.49358321896507%","--time":"3.668543053860323s","--delay":"-3.388163743221529s"}} />
      <div className="bubble" style={{"--size":"4.332626315677919rem","--distance":"9.140334569134469rem","--position":"84.2859569712186%","--time":"2.156442660109369s","--delay":"-3.6564786581282216s"}} />
      <div className="bubble" style={{"--size":"3.6860388143195832rem","--distance":"7.7481211722922865rem","--position":"15.813817089473439%","--time":"3.9198064004788384s","--delay":"-2.6648942388980394s"}} />
      <div className="bubble" style={{"--size":"2.0025313506948645rem","--distance":"7.28045416763703rem","--position":"1.281129233482499%","--time":"3.1323813941459178s","--delay":"-3.9832278226598268s"}} />
      <div className="bubble" style={{"--size":"3.7612033441841897rem","--distance":"6.222007042444526rem","--position":"17.291996866871074%","--time":"3.286137723786855s","--delay":"-2.9233097745392893s"}} />
      <div className="bubble" style={{"--size":"4.7184455384577095rem","--distance":"7.981779405855084rem","--position":"64.92560133940097%","--time":"2.9601422907308144s","--delay":"-3.2359188258830764s"}} />
      <div className="bubble" style={{"--size":"2.117815441199931rem","--distance":"8.425387864009211rem","--position":"87.87684911135493%","--time":"2.698127294205804s","--delay":"-3.1162779476438804s"}} />
      <div className="bubble" style={{"--size":"3.9713466941970665rem","--distance":"7.603281777969928rem","--position":"31.631086289855894%","--time":"3.7584042128272004s","--delay":"-2.5707399820570744s"}} />
      <div className="bubble" style={{"--size":"3.8676490180276337rem","--distance":"7.17237971258754rem","--position":"86.44235846943651%","--time":"3.584832032314754s","--delay":"-2.6921168030355713s"}} />
      <div className="bubble" style={{"--size":"2.9067476545815296rem","--distance":"8.155803165223553rem","--position":"82.78802929994539%","--time":"3.5855196856582956s","--delay":"-3.32996265264583s"}} />
      <div className="bubble" style={{"--size":"5.273496481231615rem","--distance":"8.177219839616901rem","--position":"27.157483920770673%","--time":"2.7018214690774602s","--delay":"-3.5423855394455352s"}} />
      <div className="bubble" style={{"--size":"3.5193710280926505rem","--distance":"8.330512138949018rem","--position":"34.30255477179884%","--time":"3.1306019185710974s","--delay":"-3.9251545090811413s"}} />
      <div className="bubble" style={{"--size":"5.685999656530797rem","--distance":"8.459052802856956rem","--position":"71.51040130258284%","--time":"3.167831355785756s","--delay":"-2.8422740220387364s"}} />
      <div className="bubble" style={{"--size":"5.750260380452536rem","--distance":"7.648292039915495rem","--position":"22.26942912979643%","--time":"3.2715961828680276s","--delay":"-3.932653049779823s"}} />
      <div className="bubble" style={{"--size":"2.0333811482434347rem","--distance":"8.802534234004392rem","--position":"94.87245062225142%","--time":"2.2410576994602573s","--delay":"-3.7967398346486796s"}} />
      <div className="bubble" style={{"--size":"5.598013455781689rem","--distance":"6.98928081417516rem","--position":"11.511169644971588%","--time":"2.155462990381565s","--delay":"-3.2085763655832187s"}} />
      <div className="bubble" style={{"--size":"2.6226733597955727rem","--distance":"7.53279107705842rem","--position":"8.142911106000186%","--time":"3.8480690866809995s","--delay":"-3.4431250790508185s"}} />
      <div className="bubble" style={{"--size":"4.522781352052346rem","--distance":"6.836534582578051rem","--position":"4.557799838360683%","--time":"2.6010254466450866s","--delay":"-2.3379040822052026s"}} />
      <div className="bubble" style={{"--size":"4.451299658599568rem","--distance":"9.503468199829008rem","--position":"39.13527266421123%","--time":"3.1552632053563614s","--delay":"-3.4395209077450497s"}} />
      <div className="bubble" style={{"--size":"5.8002337676771525rem","--distance":"8.815034865144222rem","--position":"64.44817531647824%","--time":"3.099151277007052s","--delay":"-2.557678321657002s"}} />
      <div className="bubble" style={{"--size":"4.984376171273565rem","--distance":"9.674999749879419rem","--position":"98.86829389688045%","--time":"2.1308963394580758s","--delay":"-2.868502987841722s"}} />
      <div className="bubble" style={{"--size":"2.477225485030832rem","--distance":"7.778374195921885rem","--position":"-0.5153806818980726%","--time":"3.927687299443051s","--delay":"-2.128436785778097s"}} />
      <div className="bubble" style={{"--size":"5.025199009704235rem","--distance":"7.344813600162648rem","--position":"58.13458470291071%","--time":"3.619069110379645s","--delay":"-3.448507283564496s"}} />
      <div className="bubble" style={{"--size":"4.0456890849867815rem","--distance":"8.234570839493887rem","--position":"15.909778634129005%","--time":"2.8027526972959076s","--delay":"-3.4381985501385546s"}} />
      <div className="bubble" style={{"--size":"2.7932819948997842rem","--distance":"9.869397397092701rem","--position":"48.28209672276769%","--time":"3.7579727390757602s","--delay":"-3.4916289187103464s"}} />
      <div className="bubble" style={{"--size":"4.905211400285409rem","--distance":"9.70361514770269rem","--position":"77.78644844557634%","--time":"2.582545376566043s","--delay":"-3.1223537973837505s"}} />
      <div className="bubble" style={{"--size":"2.161474614261632rem","--distance":"6.242763300353501rem","--position":"104.38122248553606%","--time":"3.1133309598367394s","--delay":"-3.7246383845858366s"}} />
      <div className="bubble" style={{"--size":"3.8450670919041547rem","--distance":"7.4818753972811445rem","--position":"0.6153650584957067%","--time":"3.071554387854518s","--delay":"-2.7136084730118237s"}} />
      <div className="bubble" style={{"--size":"4.310974243274291rem","--distance":"8.55030508997753rem","--position":"36.59895472564668%","--time":"2.8295807036048295s","--delay":"-2.868089055634674s"}} />
      <div className="bubble" style={{"--size":"2.9413796533893732rem","--distance":"8.563215716112914rem","--position":"97.67827165464281%","--time":"3.5920974027526036s","--delay":"-2.918406561665675s"}} />
      <div className="bubble" style={{"--size":"4.403634145062677rem","--distance":"9.815560863187239rem","--position":"47.24360451540523%","--time":"3.6518083501500542s","--delay":"-3.974638303956815s"}} />
      <div className="bubble" style={{"--size":"3.094953257104156rem","--distance":"8.398456639284895rem","--position":"7.208666111119488%","--time":"3.3266573363445304s","--delay":"-3.3875281850215395s"}} />
      <div className="bubble" style={{"--size":"4.715952257678828rem","--distance":"8.98499757658698rem","--position":"85.28152618754049%","--time":"2.715899518416479s","--delay":"-2.60382456048985s"}} />
      <div className="bubble" style={{"--size":"4.023723884341895rem","--distance":"6.629642435369736rem","--position":"10.800372434681812%","--time":"3.4022046923161593s","--delay":"-3.973142723732412s"}} />
      <div className="bubble" style={{"--size":"4.915295104267656rem","--distance":"9.16695716363435rem","--position":"5.986658978915099%","--time":"2.8909014772786614s","--delay":"-2.1158374299243197s"}} />
      <div className="bubble" style={{"--size":"4.6400150436453rem","--distance":"8.818689857151028rem","--position":"34.95838376082883%","--time":"2.8608990969754293s","--delay":"-2.360614088273121s"}} />
      <div className="bubble" style={{"--size":"4.266405495040162rem","--distance":"8.673665084362028rem","--position":"41.95989936961027%","--time":"2.57836831763809s","--delay":"-3.142357041560692s"}} />
      <div className="bubble" style={{"--size":"2.5749959888921605rem","--distance":"7.959862173290684rem","--position":"90.71616310050825%","--time":"2.7709526429000664s","--delay":"-2.7893256781667475s"}} />
      <div className="bubble" style={{"--size":"4.222813386270812rem","--distance":"7.1347273063522145rem","--position":"98.1937412756619%","--time":"2.969757978422205s","--delay":"-2.3231813063310467s"}} />
      <div className="bubble" style={{"--size":"4.156323849209735rem","--distance":"9.35497687367449rem","--position":"62.181626904245775%","--time":"3.974337509226946s","--delay":"-2.1387046437580786s"}} />
      <div className="bubble" style={{"--size":"5.623148461045989rem","--distance":"9.32510239432104rem","--position":"45.29333354695767%","--time":"3.291425232341172s","--delay":"-2.214252422717452s"}} />
    </div>
    <div className="footer_content">
      {/* <h5> - Social Links - </h5> */}
    
    
     <h3 id="qutation">
     Any fool can write code that a computer can understand. Good programmers write code that humans can understand.
     </h3>
     <span>

{
      Admin_Info_state.social_links.slice(0,6).map((el,i)=><a key={el.id}  rel="noreferrer"  target="_blank" href={el.url} >
        <i className={el.icon_name}></i>
     </a>)
    }



</span>


 <h3>©{new Date().getFullYear()} All Rights Reserved To {Admin_Info_state.first_name+" "+Admin_Info_state.last_name}</h3>

    </div>
  </div><svg style={{position:"fixed",top:"0px",left:"0px",display:"none"}}>
    <defs>
      <filter id="blob">
        <feGaussianBlur in="SourceGraphic" stdDeviation={10} result="blur" />
        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="blob" />
        {/*feComposite(in="SourceGraphic" in2="blob" operator="atop") //After reviewing this after years I can't remember why I added this but it isn't necessary for the blob effect*/}
      </filter>
    </defs>
  </svg>

</>

  )
}

export default Footer