import {React,useState,useEffect} from 'react'
import {Helmet} from "react-helmet";
import "./Forget_Password.css";
import Footer from '../Footer/Footer';
import {useDispatch,useSelector} from "react-redux";
import { https_request ,check_login_state} from '../common_functions/functions';
import {logout,login} from "../State_Manager/action/actions";
import Loading from '../c_components/Loading';

import { toast} from 'react-toastify';
import { NavLink,useNavigate } from 'react-router-dom';
let darktheme={
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
  progress: undefined,
  }




// -------------------------=================-------------------------  
function Forget_Password() {
 const navigate =useNavigate();
  const [login_condition, set_login_condition] = useState(false);

     const [cond_for_form, set_cond_for_form] = useState(false);
     const [cond_expire_button, set_cond_expire_button] = useState(false);
    // ----------------------------
    const login_out_state = useSelector((state)=>state.login_out_manager);
    const host_address=useSelector((state) => state.host_address);

    const dispatch= useDispatch();
   // ----------------------------
 
       const [form_data, setform_data] = useState({
        user_name:"",password:"",otp_code:""
       })




function manage_inputfield(e)
{
          const {name,value} =e.target;
          setform_data({...form_data,[name]:value})
}
// -----------------------------
async function request_otp_code()
{
  let res2 = await  https_request(host_address+"send_otp","POST",{user_name:form_data.user_name},true);
  console.log(res2)
  if(res2.status==true)
  {
    set_cond_expire_button(false);
    set_cond_for_form(true);
    return toast.success(res2.message.msg,darktheme);
  }else{
    
    return toast.error(res2.message.msg,darktheme);
  }
}
// -----------------------------
async function submit_Form()
{
  if(!form_data.user_name)
  {
    return toast.error('Please Enter Email...',darktheme);
  }
  else if(cond_for_form==true)
  {

    if(!form_data.password)  {
      return toast.error('Please Enter Password!',darktheme);
    }
    else if(!form_data.otp_code)  {
      return toast.error('Please Enter 6 Digit OTP !',darktheme);
    }
    else{
      let res = await  https_request(host_address+"update_password","PUT",form_data,true);
      console.log(res);
      if(res.status==true)
      {

        // ...............redirect to login page ............
        setTimeout(() => {
          
          navigate("/login")
        }, 1000);
        return toast.success(res.message.msg,darktheme);
        
      }
      else if(res.status=="expire")
      {
        set_cond_expire_button(true);
        return toast.warning(res.message.msg,darktheme);
        
      }else{
        
        return toast.error(res.message.msg,darktheme);
      }
    }
  }
  else{
    let res = await  https_request(host_address+"check_user","POST",{user_name:form_data.user_name},true);
    if(res.status==true)
    {
      request_otp_code()
      
      }else{

        return toast.error(res.message.msg,darktheme);
      }
  }
}
useEffect(() => {
  
  (  async ()=>{
    let check = await check_login_state();
    if(check==true)
    {
      navigate("/Admin")
      set_login_condition(false);

    }else{
      set_login_condition(true);
    }
  })();
  
}, [])

// ==================================================
if(login_condition==true)
{
  return (
    <>
    <Helmet>
    
    <title>Portfolio-Login</title>
    </Helmet>
   <main className="page_container forget_password_page">
   <div className="header_heading">
    

    <div className="wavi_title_container">
    
      <h1>Forget Password</h1>
      <h1>Forget Password</h1>
       <div className="temp_text">okokok</div>
      </div>
    </div>

   <form action="" className="contact_form" id='Login_form'  onSubmit={(e)=>e.preventDefault()} >
      

    <label htmlFor="user_name" className="dnone"></label>
    <input type="text" className={cond_for_form==false?"":"dnone"} onChange={manage_inputfield} value={form_data.email} placeholder='Enter User Name OR Email' id='user_name' name='user_name'/>

    <label htmlFor="password" className="dnone"></label>
    <input type="password" className={cond_for_form==true?"":"dnone"} value={form_data.password}  onChange={manage_inputfield} placeholder='Enter New Password*' name='password' id='password' />

    <label htmlFor="otp_code" className="dnone"></label>
    <input type="number" className={cond_for_form==true?"":"dnone"} value={form_data.otp_code}  onChange={manage_inputfield} placeholder='Enter 6 Digit OTP Code' name='otp_code' id='otp_code' />

   <span style={{"color":"red","margin":"1rem 0"}}>
    
   <NavLink  to={"/login"}>Want to Login!</NavLink>
   </span>

<input type="button" onClick={request_otp_code} className={cond_expire_button==true?"submit_button":"submit_button dnone"}  value={"Resend OTP Code"} />
<input type="submit" onClick={submit_Form} id='submit_button' value={"Submit"} />
   </form>

    <Footer/>
   </main>
    </>
  )
}else{
  return (<>
  
   <main className="page_container login_page">

  <Loading name={"Loading"}/>
  </main>
  </>)

}
}

export default Forget_Password