import React from 'react'
import { useState } from 'react';
import {Helmet} from "react-helmet";
import Footer from '../Footer/Footer';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import "./contact.css";
import { https_request} from '../common_functions/functions';
import { useEffect } from 'react';
let darktheme = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
  progress: undefined,
}
function Contact() {

  const Admin_Info_state = useSelector((state) => state.Admin_Data_manager);
  const host_address=useSelector((state) => state.host_address);


    const initial_contact_template={full_name:"",message:"",subject:"",email:"",user_email:""};

   const [contact_template,set_contact_template]=useState(initial_contact_template)

  function manage_input_fields(e)
  {
    const {name,value}=e.target;
    set_contact_template({...contact_template,[name]:value});

  }
  // -----------------------
  // //        Send Mail
 
  // async function Send_Mail(temp_data)
  // {
  //   const new_message =`Name: ${temp_data.full_name}<br><br>Email: ${temp_data.email}<br><br>Subject: ${temp_data.subject}<br><br>Message: <br><br>${temp_data.message} `;
  //   // const Email = await https_request("https://smtpjs.com/v3/smtp.js","get");
  //   // console.log(Email);
  //   window.Email.send({
  //     Host : "smtp.elasticemail.com",

  //       Username: "mohammadawais4667@gmail.com",
  //       Password: "bezlhyesejlxbpci",
  //       To: "mohammadawais4666@gmail.com",
  //       From: temp_data.user_email,
  //       Subject: "Client Message!",
  //       Body: new_message
  // }).then(
  //   message => alert(message)
  // );
    
  // }
  // -----------------------
  async function submit_Message()
  {
    
   try
   {

   
   if(!(contact_template.full_name.trim()))
   {
    return toast.error('Please Enter Full Name!', darktheme);
    
  }
  else if(!(contact_template.email.trim()))
  {
     return toast.error('Please Enter Email Address!', darktheme);

  }
  else if(!(contact_template.message.trim()))
  {
     return toast.error('Please Enter Message!', darktheme);

  }
  else if(!(contact_template.email.trim()))
  {
     return toast.error('Please Enter Email Address!', darktheme);

  }
  else
  {
    let contact_form_data=contact_template;
    if(!contact_template.user_email)
    {
         contact_form_data={...contact_form_data,user_email:Admin_Info_state.email};     
    }
     const res = await https_request(host_address+"send_mail_to_user","post",contact_form_data,true);
     if(res.status==true)
     {
       toast.success(res.message.msg,darktheme);
       set_contact_template(initial_contact_template);
      }else{
        
        toast.error(res.message.msg,darktheme);
     }
  }
}
catch(e)
{
  console.log(e);
  return toast.error("Something Wrong!",darktheme);
}
   
  }

  useEffect(()=>{

    set_contact_template({...contact_template,user_email:Admin_Info_state.email});

  },[Admin_Info_state]);

  return (
    <>
    <Helmet>
    
    <title>Contact</title>
    </Helmet>
   <main className="page_container contact_page">
   <div className="header_heading">
    

    <div className="wavi_title_container">
    
      <h1>Contact Me</h1>
      <h1>Contact Me</h1>
       <div className="temp_text">okokok</div>
      </div>
    </div>

   <form action="" className="contact_form" onSubmit={(e)=>{e.preventDefault();submit_Message()}}>
    <label htmlFor="full_name" className="dnone"></label>
    <input type="text" value={contact_template.full_name} onChange={manage_input_fields} placeholder='Full Name*' id='full_name' name='full_name'/>   

    <label htmlFor="email" className="dnone"></label>
    <input type="email" value={contact_template.email}  onChange={manage_input_fields} placeholder='Email*' id='email' name='email'/>

    <label htmlFor="subject" className="dnone"></label>
    <input type="subject" value={contact_template.subject}  onChange={manage_input_fields} placeholder='Subject' name='subject' id='subject' />

    <label htmlFor="message" className="dnone"></label>
    <textarea name="message" value={contact_template.message}  onChange={manage_input_fields} id="message" placeholder='Your Query...*' ></textarea>
<input type="submit" id='submit_button'   value={"Send Message"} />
   </form>

    <Footer/>
   </main>
    </>
  )
}

export default Contact