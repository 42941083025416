import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.css';
import './style/preloader.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import redux_store from './components/State_Manager/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
          <BrowserRouter>
<Provider store={redux_store}> 


    <App />
</Provider>
          </BrowserRouter>
  //  </React.StrictMode>
);
