export const logout=()=>
{
    return({
        type:"logout"
    })
    
}
export const login=()=>
{
    return({
        type:"login"
    })
    
}
export const uploading_progress_bar_Action=(newData)=>
{
    return ({
        type:"Uploading_progress_bar",
        payload:newData
    })
    
}
export const get_host_address=()=>
{
    return ({
        type:"host_address"
        
    })
    
}
export const Update_admin_data=(newData)=>
{
    return ({
        type:"update",
        payload:newData
    })
    
}
// export const increase_page_container_z_index=(zindex)=>
// {
//     return({
//         type:"increase_z_index",
//         payload:zindex
//     })
    
// }
