import React,{useState,useEffect} from 'react'
import { Update_admin_data,uploading_progress_bar_Action } from '../../State_Manager/action/actions';
import {useDispatch,useSelector} from "react-redux";




function Uploading_progress_bar() 
{

  const [uploading_progress, Setuploading_progress] = useState(0)
  const uploading_ProgressBar_State = useSelector((state)=>state.Uploading_Bar_State);
  let progress_value=0;
  
  const dispatch= useDispatch();

   function control_loading()
   {
    const progress_bar_interval= setInterval(() => {
        if(!(progress_value>=60))
        {
            // console.log(uploading_progress)
            progress_value= progress_value+10;

            if(uploading_ProgressBar_State.cond===true)
            {
                progress_value=0;
                Setuploading_progress(100);
            clearInterval(progress_bar_interval);
                
            }
            Setuploading_progress(progress_value);
        }else{
            progress_value=0;
            clearInterval(progress_bar_interval);

        }

       
         
    }, 1000);

   const second_interval= setInterval(() => {
    
       
       if(uploading_ProgressBar_State.cond===true)
       {
        console.log(uploading_ProgressBar_State);
        console.log("come here ")
        progress_value=100;
       Setuploading_progress(progress_value);
       progress_value=0;
       clearInterval(progress_bar_interval);
       clearInterval(second_interval);

       setTimeout(()=>{
        progress_value=0;
        dispatch(uploading_progress_bar_Action({target:"",cond:false}))

    },1000)
    }
   }, 1000);
   }
useEffect(() => {
  

 control_loading();
}, [uploading_ProgressBar_State])



  return (
    <div className="file_upload_progress_bar">
    <div className="outer">
      <h6>Uploading...</h6>
      <h5>{uploading_progress}%</h5>
      <div className="inner" style={{"width":uploading_progress+"%"}} >

      </div>
    </div>
   </div>
  )
}

export default Uploading_progress_bar