import dummy from "../../../assets/dummy.png";

// --------------------------------------------------------
const login_out_manager=(state=false,action)=>
{
switch (action.type) {
    case "login":
        state= true;
        return state;
    case "logout":
        state= false;
        
        return state;
   
        default:
        return state;
}


}

// --------------------------------------------------------
// const admin_dummy_data={
  
//   first_name:"Roy",
//   last_name:"Mustang",
//   name:function(){return this.first_name+" "+this.last_name},
//     email: "mohammadawais4666@gmail.com",
//     social_links:[
//       {id:"233sad42",title:"Example",url:"www.example.com",icon_name:"fas fa-atom"},
//       {id:"2332d42",title:"Example",url:"www.example.com",icon_name:"fas fa-atom"},
//       {id:"23234fsq342",title:"Example",url:"www.example.com",icon_name:"fas fa-atom"},
//       {id:"233234fdcaa42",title:"Example",url:"www.example.com",icon_name:"fas fa-atom"},
      
//     ],
//     skills:[
//       {
//         skill:"REact js",experience:80,id:"3243234324",
//       },{
//         skill:"Html5",experience:80,id:"3243234",
        
//       },{
//         skill:"Javascript",experience:80,id:"32432",

//       }
     
      
//   ],
//     services:[
//       {
//       icon:"far fa-question-circle",
//       title:"Dummy heading",
//       short_description:"loream ipsum loru pari karosum balzumi lawaris  kikasld  kjf  s lallj fdjlk alkw l jlsfdj l.i si.",
//       id:'12312312'
      
//     },
     
//   ],
//     profile_pic_link:{url:dummy},
    
//     about: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Atque saepe, doloribus nemo officia quas ad rationeLorem ipsum dolor, sit amet consectetur adipisicing elit. Atque saepe, doloem, facere, magnam obcaecati quia illo iusto totam, et corporis non officia ippiente tempora temporibus quidem, facere, magnam obcaecati quia illo iusto totam, et corporis non officia ipsam ab quo nulla omnis! Reprehenderit, tempora!",

//     profession:"Web Developer",
//     resume_link:{url:false},
//     home_background:{url:false},
//     website_link:"",
//     Projects:[
//       {
//         heading: "Temporary Heading",
//         img_url: fake_project,
//         video_id: "",
//         type: "photo",
//         id:"123121",
//         category: ["dragon"]
//       }
//     ],
//     Categories:["Javascript","dragon","Wordpress","Any"]
//   }
const admin_dummy_data={
  
  first_name:"Mohammad",
  last_name:"Awais",
    email: "example@gmail.com",
    social_links:[],
    skills:[],
    services:[],
    profile_pic_link:{url:dummy},
    
    about: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Atque saepe, doloribus nemo officia quas ad rationeLorem ipsum dolor, sit amet consectetur adipisicing elit. Atque saepe, doloem, facere, magnam obcaecati quia illo iusto totam, et corporis non officia ippiente tempora temporibus quidem, facere, magnam obcaecati quia illo iusto totam, et corporis non officia ipsam ab quo nulla omnis! Reprehenderit, tempora!",

    profession:"Example Profession",
    resume_link:{url:false},
    home_background:{url:false},
    website_link:"",
    Projects:[],
    Categories:[]
  }
// --------------------------------------------------------
const Uploading_Bar_State=(state={cond:false,value:0},action)=>
{
  const {type,payload}=action;
 if(type=="Uploading_progress_bar")
 {
  const newState= payload;
  return newState;
}
else{
   return state;

 }


}
// --------------------------------------------------------
// --------------------------------------------------------
// const default_host="https://portfolio_nodejs-1-k4573335.deta.app/";
// const default_host="http://127.0.0.1:8080/api/v1/";

const default_host="/api/v1/";
// const default_host="/";
const host_address=(state=default_host,action)=>
{
switch (action.type) {
    case "host_address":
      
        return state;

        default:
            return state;
}


}
const Admin_Data_manager=(state=admin_dummy_data,action)=>
{
switch (action.type) {
    case "update":
        const newstate= action.payload;
        return {...newstate};

        default:
            return state;
}


}
// --------------------------------------------------------




export {
  login_out_manager,
  Uploading_Bar_State,
  Admin_Data_manager,
  host_address
};