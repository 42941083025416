import { Route, Routes } from "react-router-dom";
import {Nav,Home,Admin,About,Contact,Portfolio,Services,Forget_Password, Login, Error_page} from "./components/Getcomp";
import { ToastContainer} from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";

import 'react-toastify/dist/ReactToastify.css';
import { useEffect,useState } from "react";
import { https_request } from "./components/common_functions/functions";
import { Update_admin_data } from "./components/State_Manager/action/actions";

function App() 
{
    const host_address=useSelector((state) => state.host_address);
    const Admin_Info_state = useSelector((state) => state.Admin_Data_manager);
    const dispatch = useDispatch();
        const [preloader,setPreloader] = useState(false);
        const [loading_bar_value,set_loading_bar_value] = useState(0);
        
async function Start_application()
{
  try{

  const get_user_data = await https_request(host_address+"get_user_data","get");
  if(get_user_data.data[0]==undefined)
  {
    const res_update_admin_data = await https_request(host_address+"update_user_data","post",Admin_Info_state,true);
    }
    else
    {
    dispatch(Update_admin_data(get_user_data.data[0]));

      

  }
  return true;
}
catch(e)
{
  window.location.reload();
  console.log(e);
}
}
// --------------------------- preloader useeffect code-=------------------

// --------------------------------------
  useEffect(()=>{
  let check = false;
   Start_application().then((d)=>check=d);
    
let temp_preloader=0;

const temp_interval= setInterval(()=>{
  temp_preloader=temp_preloader+1;
  if(check==false)
  {

    set_loading_bar_value(temp_preloader);
  }else{
    set_loading_bar_value(100);
    setTimeout(()=>{
      setPreloader(true);
    },3000);
    clearInterval(temp_interval);

  }
  if(temp_preloader>=40)
  {
  clearInterval(temp_interval);
      
  }
},1000);

  },[])
  
  // if(preloader==true)


  return (
   
   <>
    <div className={preloader === false?"preloader_main_container":"dnone"}>

<div className="inner_preloader_container">
    
    <h1 id="loading_preloader_heading" >Loading</h1>
    <div className="loading_bar_container">
        <div className="loading_bar" style={{width:loading_bar_value+"%"}}></div>
    </div>
    <h5 id="loading_preloader_progress" >{loading_bar_value}%</h5>
</div>
</div>
     
      <ToastContainer/>
   <Nav/>
      <Routes>
        <Route   path="/" element={<Home/>} />
        <Route   path="/About" element={<About/>} />
        <Route   path="/contact" element={<Contact/>} />
        <Route   path="/Portfolio" element={<Portfolio/>} />
        <Route   path="/Services" element={<Services/>} />
        <Route   path="/Login" element={<Login/>} />
        <Route   path="/Forget_Password" element={<Forget_Password/>} />
        <Route   path="/Admin" element={<Admin/>} />
        <Route   path="*" element={<Error_page/>} />
       
        </Routes>
   </>
  );



}

export default App;
