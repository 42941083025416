import React from 'react'
import "./loading.css";
function Loading(Props) {
  return (
    <div className='loading_container'>
    <div className="loading_outer">

    <div className='loading_inner'>
    </div>
     <h3>{Props.name}...</h3>
    </div>
    </div>
  )
}

export default Loading