import {React,useState,useEffect} from 'react'
import {Helmet} from "react-helmet";
import "./login.css";
import Footer from '../Footer/Footer';
import {useDispatch,useSelector} from "react-redux";
import { check_login_state, https_request } from '../common_functions/functions';
import {logout,login} from "../State_Manager/action/actions";

import { toast} from 'react-toastify';
import { NavLink, useNavigate} from 'react-router-dom';
import Loading from '../c_components/Loading';
let darktheme={
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
  progress: undefined,
  }




// -------------------------=================-------------------------  
function Login() {

    // ----------------------------
    const login_out_state = useSelector((state)=>state.login_out_manager);
    const host_address=useSelector((state) => state.host_address);

    const dispatch= useDispatch();
    const navigate = useNavigate();
   // ----------------------------
 
       const [login_condition, set_login_condition] = useState(false);
       const [form_data, setform_data] = useState({
        user_name:"",password:""
       })




function manage_inputfield(e)
{
          let {name,value} =e.target;
          if(name==="user_name")
          {
            value = value.trim();

          }
          setform_data({...form_data,[name]:value})
}
// -----------------------------
async function submit_Form()
{
  if(!(form_data.user_name))
  {
    return toast.error('Please Enter Email OR User Name',darktheme);
  }
  else if(!form_data.password)  {
    return toast.error('Please Enter Password!',darktheme);
  }
  else{
    console.log(host_address+"login");
  let res = await  https_request(host_address+"login","POST",form_data,true);
  console.log(res);
    if(res.status===true)
    {
        dispatch(login());
        setTimeout(()=>{

          navigate("/Admin");
        },1500)

    return toast.success('Login Successfully!',darktheme);
    
  }else{
      return toast.error(res.message.msg,darktheme);

    }
  }
}
// -----------------------------
useEffect(() => {
  
  (  async ()=>{
    let check = await check_login_state();
    if(check==true)
    {
      navigate("/Admin")
      set_login_condition(false);

    }else{
      set_login_condition(true);
    }
  })();
  
}, [])

if(login_condition==true)
{

     
  return (
    <>
    <Helmet>
    
    <title>Login</title>
    </Helmet>
   <main className="page_container login_page">
   <div className="header_heading">
    

    <div className="wavi_title_container">
    
      <h1>Login Page</h1>
      <h1>Login Page</h1>
       <div className="temp_text">okokok</div>
      </div>
    </div>

   <form action="" className="contact_form" id='Login_form'  onSubmit={(e)=>e.preventDefault()} >
      

    <label htmlFor="user_name" className="dnone"></label>
    <input type="text" onChange={manage_inputfield} value={form_data.email} placeholder='User Name OR Email*' id='user_name' name='user_name'/>

    <label htmlFor="password" className="dnone"></label>
    <input type="password" value={form_data.password}  onChange={manage_inputfield} placeholder='Password*' name='password' id='password' />

   <span style={{"color":"red","margin":"1rem 0"}}>
    
   <NavLink  to={"/forget_password"}>Forget Password!</NavLink>
   </span>
<input type="submit" onClick={submit_Form} id='submit_button' value={"Login"} />
   </form>

    <Footer/>
   </main>
    </>
  )
}else{
  return (<>
  
   <main className="page_container login_page">

  <Loading name={"Loading"}/>
  </main>
  </>)

}

}

export default Login