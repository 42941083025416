import {React,useState,useEffect} from 'react';
import "./Admin_Projects.css";
import { Update_admin_data } from '../../State_Manager/action/actions';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { trim_text, generate_random,Test_File,Toggle_class_name, https_request } from '../../common_functions/functions';
let darktheme = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
  progress: undefined,
}


// ------------------------------Actual funtion ---------------------------
function Admin_Projects({ Setupdate_condition, Disabled_cond, update_condition, Upload_File_To_Server })
{
  const Admin_Info_state = useSelector((state) => state.Admin_Data_manager);
  const dispatch = useDispatch();
  const [temp_Admin_Data, Set_temp_Admin_Data] = useState(Admin_Info_state);
  const initial_project_template= {
    heading: "",
    img_url: "",
    img_type:"",
    link:"",
    video_id: "",
    type: "",
    id:"",
    category: []
  }
  const type1="website";
  const type2="photo";
  const [project_template, Set_project_template] = useState(initial_project_template);

 const host_address= useSelector((state) => state.host_address);



// ------------------------------Function Part ------------------------------
function reset_project_creation_portal()
{
  let project_name = document.getElementById("project_name");
  let video_url = document.querySelector("#project_video_url");
  let check_boxes = document.querySelectorAll(".check_boxes:checked");
  let create_project_btn = document.querySelector("#create_project_btn");
  let update_project_btn = document.querySelector("#update_project_btn");
  let portfolio_project_manager_heading = document.querySelector("#portfolio_project_manager_heading");
  Set_project_template(initial_project_template);

        project_name.value="";
        video_url.value="";
        let ele1 = document.querySelector(".unselected_button1");
        let ele2 = document.querySelector(".unselected_button2");
        portfolio_project_manager_heading.innerHTML="Create Project";
     Toggle_class_name(update_project_btn,"dnone","open");
     Toggle_class_name(create_project_btn,"dnone","close");
        Toggle_class_name(ele2,"show_project_type","close");
        Toggle_class_name(ele1,"show_project_type","close");
        check_boxes.forEach((el,i)=>{
          el.checked=false;
         })
}
async function project_page_manager(e,cond)
{
  if(cond === "add_filter_btn")
  {
     const element =document.querySelector("#add_new_filter_button_field");
     const button_name_value = element.value.trim();
     if (button_name_value=="")
     {
       return toast.error('Please Enter Filter Button Name!', darktheme);
       
      }
     else if (button_name_value.toLowerCase()=="all")
     {
       return toast.error('"All" this name is not allowed!', darktheme);
       
      }
      else
      {
        let new_Categories = temp_Admin_Data.Categories;
        new_Categories.push(button_name_value);
        
        Set_temp_Admin_Data({...temp_Admin_Data,Categories:new_Categories});
         element.value="";
        return toast.success('New Filter Button Created Successfully!', darktheme);

      }

  }
  else if(cond === "remove_filter_btn")
  {
      let button_value = e.target.getAttribute("target");
     if (button_value=="All" || button_value=="ALL")
     {
       return toast.error('This Operation is not Allowed!', darktheme);
       
      }
      
      
        let new_Categories = temp_Admin_Data.Categories;
        new_Categories= new_Categories.filter((e)=>e!=button_value);
        
        Set_temp_Admin_Data({...temp_Admin_Data,Categories:new_Categories});
        return toast.success('Filter Button Deleted Successfully!', darktheme);

      

  }
  else if(cond === "open_project_creation_portal")
  {
    const element =document.querySelector(".portfolio_project_creater_updater");
    Toggle_class_name(element,"perfect_position_projectportal","open");
  }
  else if(cond == "close_project_creation_portal")
  {
    const element =document.querySelector(".portfolio_project_creater_updater");
    Toggle_class_name(element,"perfect_position_projectportal","close");
    reset_project_creation_portal();

  }
  else if(cond == "upload_file")
  {
    const file = document.getElementById("upload_project_image").files[0];
    document.getElementById("upload_project_image").value = null;
    if (Test_File(file, "image_project") === true) {


      let res = await Upload_File_To_Server(file);
      if (res.status === true) {
                   
        const imgFile= res.data;
        // console.log(imgFile);
        console.log(res.data);
        
         Set_project_template({...project_template,img_url:imgFile})
      }
      else if (res.status !== true) {

        return toast.error(res.message.msg, darktheme);

      }
    }

  }
  else if(cond == "create_new_project" || cond =="update_project")
  {
    let project_name = document.getElementById("project_name");
    let video_url = document.querySelector("#project_video_url");
    let check_boxes = document.querySelectorAll(".check_boxes:checked");
    if(!project_name.value.trim())
    {
      return toast.error('Please, Enter Project Name!', darktheme);
    }
    else if(!project_template.type)
    {
     return toast.error('Please, Select Project Type!', darktheme);
     
    }
    else if(!project_template.img_url)
    {
     return toast.error('Please, Upload Project Image!', darktheme);
     
    }
    else if(check_boxes.length==0 && check_boxes[0] == undefined)
    {
     return toast.error('Please, Select aleast ONE Filter Button!', darktheme);

   }
   if(project_template.type==type1)
    {

      if(!video_url.value.trim())
      {
        return toast.error('Please, Enter Video URL!', darktheme);
      }

    }
    
   
    let temp_check_boxes_value=[];
     check_boxes.forEach((el,i)=>{
      temp_check_boxes_value.push(el.value);
     })
    
     project_template.heading=project_name.value.trim();
     project_template.category=temp_check_boxes_value;
     if(project_template.type==type1)
     {
          project_template.video_id=video_url.value.trim();
          
        }else{
          project_template.video_id="";

        }
      if(cond=="update_project")  
      {
       let updated_projects=[];
       temp_Admin_Data.Projects.filter((e,i)=>{
          if(e.id==project_template.id)
          {
            
            updated_projects.push(project_template);
          }
          else{
            updated_projects.push(e)
          }
          return e;
        })
        Set_temp_Admin_Data({...temp_Admin_Data,Projects:updated_projects});
        // reset_project_creation_portal();
        // project_page_manager(e,"close_project_creation_portal");
        return toast.success('Project Updated Successfully!', darktheme);
      
      }
      else{

        project_template.id=generate_random(6);
        let new_projects = temp_Admin_Data.Projects;
        new_projects.unshift(project_template);
        Set_temp_Admin_Data({...temp_Admin_Data,Projects:new_projects});
        reset_project_creation_portal();
        return toast.success('New Project Created Successfully!', darktheme);
      }
     
        
        
        
     
   
   
   
     
  }
  else if(cond == "delete_project")
  {
    if(window.confirm("Do you really want to delete this project?"))
    {

      const project_id=e.target.getAttribute("target");
      let temp_projects = temp_Admin_Data.Projects;
      temp_projects = temp_projects.filter((e)=>e.id!=project_id);
      Set_temp_Admin_Data({...temp_Admin_Data,Projects:temp_projects});
    }

  }
  else if(cond == "edit_project")
  {
    const project_id=e.target.getAttribute("target");
    
    let temp_project = temp_Admin_Data.Projects.filter((e)=>e.id==project_id);
    temp_project=temp_project[0];
    // Set_temp_Admin_Data({...temp_Admin_Data,Projects:temp_projects});
    if(temp_project==undefined ||temp_project== null || temp_project==0 || temp_project== "")
    {
      return toast.error("Something Wrong! Project Not Found!",darktheme);
    }
    let project_name = document.getElementById("project_name");
    let video_url = document.querySelector("#project_video_url");
    let create_project_btn = document.querySelector("#create_project_btn");
    let update_project_btn = document.querySelector("#update_project_btn");
    let portfolio_project_manager_heading = document.querySelector("#portfolio_project_manager_heading");
    let check_boxes = document.querySelectorAll(".check_boxes");
    let video_ele = document.querySelector("#project_video_url");
    let website_button = document.querySelector(".unselected_button1");
    let photo_button = document.querySelector(".unselected_button2");

    project_name.value=temp_project.heading;
    video_url.value=temp_project.video_id;

    Set_project_template({...project_template,heading:temp_project.heading,img_url:temp_project.img_url,id:temp_project.id,video_id:temp_project.video_id,type:temp_project.type,category:temp_project.category})

    if(temp_project.type==type1)
    {

      Toggle_class_name(photo_button,"show_project_type","close");
      Toggle_class_name(website_button,"show_project_type","open");
      Toggle_class_name(video_ele,"dnone","close");
      
    }else{
      Toggle_class_name(photo_button,"show_project_type","open");
      Toggle_class_name(website_button,"show_project_type","close");
      Toggle_class_name(video_ele,"dnone","open");

    }
    
    check_boxes.forEach((el,i)=>{
      temp_project.category.forEach((project_ele)=>{
        if(el.value==project_ele)
        {
          el.checked=true;
        }
      })
     })
     portfolio_project_manager_heading.innerHTML="Edit Project";
     Toggle_class_name(update_project_btn,"dnone","close");
     Toggle_class_name(create_project_btn,"dnone","open");
     project_page_manager(e,"open_project_creation_portal");
  }
 

}
// ------------------------------------------------
async function manage_update_funtionality()
{
  if (update_condition == "close") {
    Set_temp_Admin_Data(Admin_Info_state);
    Setupdate_condition(false);

  }
  else if (update_condition == "update") {
    // update_Admin_data();
    const new_data = temp_Admin_Data;
    const update_res= await https_request(host_address+"update_user_data","post",new_data,true);
    if(update_res.status==true)
    {
      Setupdate_condition(false);
      dispatch(Update_admin_data(new_data));
      toast.success(update_res.message.msg,darktheme);
    }else{
      toast.error(update_res.message.msg,darktheme);
    }
    


  }

}
useEffect(() => {

     
  manage_update_funtionality();
 
}, [update_condition])



  
    // ..................... Returnig .................... 
  return (
        <>


    <div className='AdminPanel_Projects'>

     <h1 target="heading">Portfolio Setting</h1>
     <hr />
     <div className="project_filter_btn_container_outer">
     <h2 target="filter_btn_heading">Filter Buttons</h2>

            <div className='project_filter_btn_container_inner'>

              {
                temp_Admin_Data.Categories.map((val,i)=>  <span key={i} className={Disabled_cond==true?"":'filter_buttons'}>
                <button >{val}</button>
                <i className={Disabled_cond==true?"dnone":"fas fa-times"} target={val} onClick={(e)=>project_page_manager(e,"remove_filter_btn")} ></i>

             </span>)

              }  
              {/* ----------------------          */}
          {
            (temp_Admin_Data.Categories[0] == undefined && temp_Admin_Data.Categories.length == 0)
              ?
              <h5 className='empty_heading_admin'>Empty <i className="fas fa-shopping-basket"></i></h5>
              :
              ""

            }
            
            </div>
        {/* -------------------------------------------------- */}

           <input type="text" id='add_new_filter_button_field' className={Disabled_cond==true?"dnone":''} onKeyDown={(e)=>{
               if(e.code=="Enter")
               {

                 project_page_manager(e,"add_filter_btn");
                }

            }} placeholder='Enter Button Name'  disabled={Disabled_cond} />

        <button type='button' onClick={(e)=>project_page_manager(e,"add_filter_btn")}  className={Disabled_cond==true?"dnone":'cbtn_small'}>Add New</button>
     </div>
{/* ------------------ Portfolio Projects ------------------------------ */}
        <hr />

        <div className="project_container">

        <h2>Portfolio Projects</h2>
          
          <div className="project_container_inner">
            {
              temp_Admin_Data.Projects.map(({img_url,id,heading},i)=><div key={i} className="single_project">
              <div className="project_img_container">
                <img src={`${img_url.url}`} alt="project_image" />
              </div>
              <h3>{heading}</h3>

              <div className={Disabled_cond==true?"dnone":'edit_del_container '}>
                <i className="fas fa-edit" onClick={(e)=>project_page_manager(e,"edit_project")}  target={id}></i>
                <i className="fas fa-trash" onClick={(e)=>project_page_manager(e,"delete_project")} target={id}></i>
              </div>

            </div>)
            }
            
          {/* --------------------------------------------------------- */}

          </div>
          <button type='button' id='creatin_portal_caller_btn'  onClick={(e)=>project_page_manager(e,"open_project_creation_portal")}  className={Disabled_cond==true?"dnone":'cbtn_small'}>Create Project</button>

        </div>
        {/* ----------------- project creation portal ------------------------ */}
        <div className="portfolio_project_creater_updater">
             
             <i className="far close_portal_icon fa-times-circle" onClick={(e)=>project_page_manager(e,"close_project_creation_portal")}></i>
        <div className="project_creation_portal_inner">

          <h1 id='portfolio_project_manager_heading'>Create Project</h1>
          

         
        <input type="text" placeholder='Enter Project Name' className='common_input_style' id='project_name' />
         <div className="project_type_container">
          <span>Type</span>
          <div className='unselected_button1' >

          <button className='cbtn_small' onClick={(e)=>{
               let ele1 = document.querySelector(".unselected_button1");
               let ele2 = document.querySelector(".unselected_button2");
               let video_ele = document.querySelector("#project_video_url");
                Toggle_class_name(ele1,"show_project_type","open");
                Toggle_class_name(ele2,"show_project_type","close");
                Toggle_class_name(video_ele,"dnone","close");
                Set_project_template({...project_template,type:type1});
          }}>Website</button>

          <i className='fas fa-check'></i>
          </div>
          <div className='unselected_button2'>
          <i className='fas fa-check'></i>
          <button className='cbtn_small' onClick={(e)=>{
               let ele2 = document.querySelector(".unselected_button1");
               let ele1 = document.querySelector(".unselected_button2");
               let video_ele = document.querySelector("#project_video_url");
               Toggle_class_name(video_ele,"dnone","open");

                Toggle_class_name(ele1,"show_project_type","open");
                Toggle_class_name(ele2,"show_project_type","close");
                Set_project_template({...project_template,type:type2});

          }}>Photo</button>
          </div>
         </div>
        <input type="text" className='common_input_style' placeholder='Enter Video URL (Only Youtube)' id='project_video_url' /> 
        <div className="project_categories_container">
          <span>Select Filter Buttons</span>
          {
            temp_Admin_Data.Categories.map((val,i)=> <div key={i}>

            <input type="checkbox" className='check_boxes' id={val} value={val} />
            <label htmlFor={val}>{val}</label>
            </div>)
          }
         
        
         
         
        </div>

        <div className="img_container">
               
        {
           project_template.img_url==""
              ?
              <h5 className='empty_heading_admin'>Empty <i className="fas fa-shopping-basket"></i></h5>
              :
              ""

            }
            {
              project_template.img_url!==""
              ?<img  id='preview_project_image' src={`${(project_template.img_url.url)}`}  alt="Project Preview" />
              :""
            }
          
          </div>
          {
          Disabled_cond == true ?
            <input type="text" className="dnone" id="upload_project_image" />
            :
            <input type="file" name="myImage" onChange={(e) => project_page_manager(e,"upload_file")} accept="image/png, image/jpeg,image/jpg" id="upload_project_image" className="dnone" />
          }

        <label className='common_input_style' htmlFor='upload_project_image'>Upload Project Image</label>
        <button type='button' className='cbtn' onClick={(e)=>project_page_manager(e,"create_new_project")} id='create_project_btn'>Create It</button>
        <button type='button' onClick={(e)=>project_page_manager(e,"update_project")} className='cbtn dnone' id='update_project_btn'>Update It</button>
        </div>
      </div>

    </div>
    </>

  )
}

export default Admin_Projects