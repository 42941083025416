import { React, useState, useEffect, lazy, Suspense } from 'react'
import { Helmet } from "react-helmet";
import Footer from '../Footer/Footer';
import "./portfolio.css";
import { useDispatch, useSelector } from "react-redux";

// import Single_project from '../c_components/Single_project';
import { CgClose } from 'react-icons/cg';
import { FaTrash } from 'react-icons/fa';
import Loading from '../c_components/Loading';
const Single_project = lazy(() => import('../c_components/Single_project'));
const typ1 = "website";
const typ2 = "photo";



function Portfolio() {

  const [ViewedProject,SetViewedProject] = useState([]);

  const [page_container_z_index, set_page_container_z_index] = useState(5);
  const Admin_Info_state = useSelector((state) => state.Admin_Data_manager);


  const [toggle_video_player, settoggle_video_player] = useState(false);
  const [toggle_img_viewer, settoggle_img_viewer] = useState(false);
  const [current_video_id, setcurrent_video_id] = useState("");
  const [current_img_url, setcurrent_img_url] = useState("");

  const [cond_for_empty_heading,set_cond_for_empty_heading]=useState(false);
  const [cond_for_load_button,set_cond_for_load_button]=useState(false);
  const [allproject,setallprojects]=useState([]);
  const [projects,setprojects]=useState(Admin_Info_state.Projects);

  const [filter_buttons_cond,setfilter_buttons_cond]=useState("All");

  const default_number_of_elements=12;
  const [number_of_elements,setnumber_of_elements]=useState(default_number_of_elements);

  // custom functions 
  function open_image(id) {
    setcurrent_img_url(id);
    settoggle_img_viewer(!toggle_img_viewer);
  }
  // -------------------
  function play_video(id) {
    setcurrent_video_id(id);
    settoggle_video_player(!toggle_video_player);
  }
  // -------------------

  function stop_videos() {
    const videos = document.querySelectorAll('iframe');
    settoggle_video_player(!toggle_video_player);


    videos.forEach(i => {
      const source = i.src
      i.src = ''
      i.src = source
    })


  }
  // ------------------------------
  const load_more_projects=async ()=>
  {
    let new_value=number_of_elements+6;
   setnumber_of_elements(new_value);
    console.log("come here")
    if(new_value>=allproject.length)
    {
       set_cond_for_load_button(!cond_for_load_button);
    }
    
  }
  // ------------------------------
  const fillter_projects=async (e)=>
  {
    setfilter_buttons_cond(e.target.getAttribute("target"));
    const buttons =document.querySelectorAll(".fillter_buttons");
    await buttons.forEach((ele,index)=>ele.classList.remove("active_button"));

    e.target.classList.add("active_button");
    let custom_projects=[];
    if(e.target.getAttribute("target")!="All")
    {
       custom_projects= projects.filter((ele)=>{
      if(ele.category.includes(e.target.getAttribute("target")))
      {
          return ele;
      }
    })
    setallprojects(custom_projects);

  }
  else{
     custom_projects = projects;
       setallprojects(projects);

  }

    if(custom_projects.length==0 && custom_projects[0]==undefined )
    {
      set_cond_for_empty_heading(true);
      
    }else{
      
      set_cond_for_empty_heading(false);
    }
    
  

  }
  // ------------------------------
const Get_filter_button=({val})=>{

return <>
  <button className={filter_buttons_cond==val?"fillter_buttons active_button":"fillter_buttons"} target={val} onClick={fillter_projects}>{val}</button>

  </>

  }
  // ------------------------------
  useEffect(()=>{
    setnumber_of_elements(default_number_of_elements);
    if(number_of_elements>=allproject.length)
    {
       set_cond_for_load_button(true);
      }else{
        
        set_cond_for_load_button(false);
    }

  },[filter_buttons_cond,allproject])
  // -----------------------------
  useEffect(()=>{
    if(number_of_elements>=allproject.length)
    {
       set_cond_for_load_button(!cond_for_load_button);
    }

    setallprojects(projects);
  },[])
useEffect(()=>{
  setprojects(Admin_Info_state.Projects);
  setallprojects(Admin_Info_state.Projects);

},[Admin_Info_state])

  return (
    <>
      <Helmet>

        <title>Portfolio</title>
      </Helmet>

      <main className=" page_container about_page" style={{"zIndex":page_container_z_index}} >
        <div className="header_heading">


          <div className="wavi_title_container">

            <h1>My Work</h1>
            <h1>My Work</h1>
            <div className="temp_text">okokok</div>
          </div>
        </div>
        <Suspense fallback={<Loading name={"Loading"}/>}>

          <section className="my_work_container">
            <div className="filter_buttons_container">
              <div className="filter_buttons">
              <Get_filter_button val={"All"} key={"12312342134121"} />
                {
                  Admin_Info_state.Categories.map((val, index) =><Get_filter_button val={val} key={index} />)
                }
              </div>
            </div>


            <div className="all_projects">

                
              {
                allproject.slice(0,number_of_elements).map((data, index) => <Single_project 
                ViewedProject={ViewedProject}
                SetViewedProject={SetViewedProject}
                open_image={open_image} set_page_container_z_index={set_page_container_z_index} data={data} key={index} play_video={play_video} />)
              }
              {/* <Single_project img_url={food_website}/>
      <Single_project img_url={img_url}/>
      <Single_project img_url={food_website}/> */}
            
            </div>

            <div style={{"width":"100%","display":"flex","justifyContent":"center","alignItems":"center"}}>
         <h2 className={cond_for_empty_heading==true?"empty_heading":" empty_heading onone"} >Empty <span >
            <FaTrash/>
            </span>
            </h2>
          </div>



            <div style={{"width":"100%","display":"flex","justifyContent":"center","alignItems":"center"}}>
        

            <button className={cond_for_load_button==true?"dnone cbtn":"cbtn"} onClick={(e)=>load_more_projects()}   style={{
              "marginTop":"0rem",
              "boxShadow":"none",
              "border":"none",
              "color":"white",
              "background": "#00000052",
              "zIndex":'1'
                  
          }}>Load More</button>
            
            </div>





          </section>
        </Suspense>
        {/* --------------------------------- video player ...................... / */}
        <div className={toggle_video_player == true ? "video_player_container perfect_position" : "video_player_container"}>

          <span onClick={()=>{
            set_page_container_z_index(5);
            stop_videos();
            }} ><CgClose /></span>

          <iframe src={current_video_id?`https://www.youtube.com/embed/${current_video_id}`:""} title="Figma to Html ( GTP-3 Website) by using React JS" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        {/* image viewer */}

        <div className={toggle_img_viewer == true ? "video_player_container img_viewer perfect_position" : "video_player_container img_viewer"}>

          <span onClick={() => {
             set_page_container_z_index(5);
            settoggle_img_viewer(!toggle_img_viewer);
            }} ><CgClose /></span>
           <div className="image_viewer_container">

          <img src={`${current_img_url.url}`} alt="View Project" />
           </div>

        </div>


        {/* ------------------------------ */}
        <Footer />
      </main>
    </>
  )
}

export default Portfolio